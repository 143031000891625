import {
  Box,
  Button,
  Flex,
  Img,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

import bannerSubLogo from "../../../../assets/images/home/Group 3934 2.png";
const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });

  return (
    <>
      {/* bg-image-content-div */}
      <Box className="background-image-banner ">
        <Flex
          alignItems="center"
          paddingY={{ base: "80px", xl: "0px" }}
          flexDirection={{ base: "column", xl: "row" }}
          justifyContent="space-between"
          height={{ base: "inherit", xl: "777px" }}
          className={isXLScreen ? "container" : "container-responsive"}
        >
          <Box minWidth="52%">
            <Text
              color="#FFF"
              fontSize={{ base: "27px", md: "40px", xl: "64px" }}
              fontStyle="normal"
              fontWeight={{ base: "600", md: "500" }}
              lineHeight={{ base: "40px", xl: "70px" }}
            >
              Join the Private Markets Revolution
            </Text>
            <Text
              paddingTop="20px"
              color="#FFF"
              fontSize={{ base: "16px", xl: "24px" }}
              fontStyle="normal"
              fontWeight="300"
              lineHeight={{ base: "30px", xl: "40px" }}
            >
              AKRU On-Fi revolutionizes private markets by bringing efficiency,
              transparency and cost-effectiveness to the forefront through the
              power of blockchain technology and natural language processing.
            </Text>
            <Box marginTop="30px">
              <Link href="/learn-more-home">
                <Button
                  borderRadius="5px"
                  fontSize={{ base: "14px", xl: "16px" }}
                  color="white"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  paddingX="26px"
                  paddingY="18px"
                  backgroundColor="#FD8534"
                  _hover={{ backgroundColor: "#FD8534CC" }}
                  colorScheme="white"
                >
                  Learn More
                </Button>
              </Link>
            </Box>
          </Box>
          <Flex
            paddingTop={{ base: "30px", xl: "0px" }}
            alignItems="center"
            justifyContent="center"
            minW={{ base: "100%", xl: "60%" }}
          >
            <Img src={bannerSubLogo} />
          </Flex>
        </Flex>
      </Box>
      {/* boxes-div */}
      {/* <Box
        display={{ base: "block", xl: "none" }}
        pos="absolute"
        top="0px"
        height="fit-content"
        right="0px"
        left="0px"
        bottom="0px"
      >
        <HStack
          className={isXLScreen ? "container" : "container-responsive"}
          sx={{
            overflowX: "scroll",
            "::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
          spacing="20px"
        >
          <Box
            minWidth={{ base: "100%", sm: "50%", md: "40%", xl: "32%" }}
            backgroundColor="white"
            borderRadius="5px"
            overflow="hidden"
            height="fit-content"
          >
            <Flex
              paddingX={{ base: "10px", xl: "35px" }}
              paddingY={{ base: "14px", xl: "28px" }}
              alignItems="center"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                backgroundColor="#FFF3EB"
                width={{ base: "40px", xl: "70px" }}
                height={{ base: "40px", xl: "70px" }}
                borderRadius="5px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                >
                  <path
                    d="M1.55556 19.7778H3.77778C4.63556 19.7778 5.33333 19.08 5.33333 18.2222V8C5.33333 7.14222 4.63556 6.44444 3.77778 6.44444H1.55556C0.697778 6.44444 0 7.14222 0 8V18.2222C0 19.08 0.697778 19.7778 1.55556 19.7778ZM11.3609 0C10.472 0 10.0276 0.444444 10.0276 2.66667C10.0276 4.77867 7.98222 6.47822 6.66667 7.35378V18.3653C8.08978 19.024 10.9387 20 15.3609 20H16.7831C18.5164 20 19.992 18.7556 20.2853 17.0489L21.2809 11.2711C21.3693 10.7607 21.345 10.2371 21.2097 9.73697C21.0744 9.23689 20.8315 8.77244 20.4978 8.37613C20.1642 7.97983 19.7479 7.66126 19.2782 7.44274C18.8085 7.22422 18.2967 7.11103 17.7787 7.11111H13.5831C13.5831 7.11111 14.2498 5.77778 14.2498 3.55556C14.2498 0.888889 12.2498 0 11.3609 0Z"
                    fill="#FD8534"
                  />
                </svg>
              </Flex>
              <Box paddingLeft="27px">
                <Text
                  color="#FD8534"
                  fontSize={{ base: "24px", xl: " 40px" }}
                  fontStyle="normal"
                  fontWeight=" 600"
                  lineHeight="40px"
                >
                  98%
                </Text>
                <Text
                  color="#666"
                  fontSize={{ base: "12px", xl: " 20px" }}
                  fontStyle="normal"
                  fontWeight=" 400"
                  lineHeight={{ base: "20px", xl: "40px" }}
                >
                  Satisfaction Rate
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box
            minWidth={{ base: "100%", sm: "50%", md: "40%", xl: "32%" }}
            backgroundColor="white"
            borderRadius="5px"
            overflow="hidden"
            height="fit-content"
          >
            <Flex
              paddingX={{ base: "10px", xl: "35px" }}
              paddingY={{ base: "14px", xl: "28px" }}
              alignItems="center"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                backgroundColor="#FFF3EB"
                width={{ base: "40px", xl: "70px" }}
                height={{ base: "40px", xl: "70px" }}
                borderRadius="5px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                >
                  <path
                    d="M1.55556 19.7778H3.77778C4.63556 19.7778 5.33333 19.08 5.33333 18.2222V8C5.33333 7.14222 4.63556 6.44444 3.77778 6.44444H1.55556C0.697778 6.44444 0 7.14222 0 8V18.2222C0 19.08 0.697778 19.7778 1.55556 19.7778ZM11.3609 0C10.472 0 10.0276 0.444444 10.0276 2.66667C10.0276 4.77867 7.98222 6.47822 6.66667 7.35378V18.3653C8.08978 19.024 10.9387 20 15.3609 20H16.7831C18.5164 20 19.992 18.7556 20.2853 17.0489L21.2809 11.2711C21.3693 10.7607 21.345 10.2371 21.2097 9.73697C21.0744 9.23689 20.8315 8.77244 20.4978 8.37613C20.1642 7.97983 19.7479 7.66126 19.2782 7.44274C18.8085 7.22422 18.2967 7.11103 17.7787 7.11111H13.5831C13.5831 7.11111 14.2498 5.77778 14.2498 3.55556C14.2498 0.888889 12.2498 0 11.3609 0Z"
                    fill="#FD8534"
                  />
                </svg>
              </Flex>
              <Box paddingLeft="27px">
                <Text
                  color="#FD8534"
                  fontSize={{ base: "24px", xl: " 40px" }}
                  fontStyle="normal"
                  fontWeight=" 600"
                  lineHeight="40px"
                >
                  97 M
                </Text>
                <Text
                  color="#666"
                  fontSize={{ base: "12px", xl: " 20px" }}
                  fontStyle="normal"
                  fontWeight=" 400"
                  lineHeight={{ base: "20px", xl: "40px" }}
                >
                  Satisfaction Rate
                </Text>
              </Box>
            </Flex>
          </Box>
          <Box
            minWidth={{ base: "100%", sm: "50%", md: "40%", xl: "32%" }}
            backgroundColor="white"
            borderRadius="5px"
            overflow="hidden"
            height="fit-content"
          >
            <Flex
              paddingX={{ base: "10px", xl: "35px" }}
              paddingY={{ base: "14px", xl: "28px" }}
              alignItems="center"
            >
              <Flex
                alignItems="center"
                justifyContent="center"
                backgroundColor="#FFF3EB"
                width={{ base: "40px", xl: "70px" }}
                height={{ base: "40px", xl: "70px" }}
                borderRadius="5px"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                >
                  <path
                    d="M1.55556 19.7778H3.77778C4.63556 19.7778 5.33333 19.08 5.33333 18.2222V8C5.33333 7.14222 4.63556 6.44444 3.77778 6.44444H1.55556C0.697778 6.44444 0 7.14222 0 8V18.2222C0 19.08 0.697778 19.7778 1.55556 19.7778ZM11.3609 0C10.472 0 10.0276 0.444444 10.0276 2.66667C10.0276 4.77867 7.98222 6.47822 6.66667 7.35378V18.3653C8.08978 19.024 10.9387 20 15.3609 20H16.7831C18.5164 20 19.992 18.7556 20.2853 17.0489L21.2809 11.2711C21.3693 10.7607 21.345 10.2371 21.2097 9.73697C21.0744 9.23689 20.8315 8.77244 20.4978 8.37613C20.1642 7.97983 19.7479 7.66126 19.2782 7.44274C18.8085 7.22422 18.2967 7.11103 17.7787 7.11111H13.5831C13.5831 7.11111 14.2498 5.77778 14.2498 3.55556C14.2498 0.888889 12.2498 0 11.3609 0Z"
                    fill="#FD8534"
                  />
                </svg>
              </Flex>
              <Box paddingLeft="27px">
                <Text
                  color="#FD8534"
                  fontSize={{ base: "24px", xl: " 40px" }}
                  fontStyle="normal"
                  fontWeight=" 600"
                  lineHeight="40px"
                >
                  98%
                </Text>
                <Text
                  color="#666"
                  fontSize={{ base: "12px", xl: " 20px" }}
                  fontStyle="normal"
                  fontWeight=" 400"
                  lineHeight={{ base: "20px", xl: "40px" }}
                >
                  Satisfaction Rate
                </Text>
              </Box>
            </Flex>
          </Box>
        </HStack>
      </Box> */}
    </>
  );
};

export default Banner;
