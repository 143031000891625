import { Box, Flex, Image } from "@chakra-ui/react";
import React from "react";
import AkruLogo from "../../assets/images/comingSoon/Group 404.png";
const ComingSoon = () => {
  return (
    <Box className="background-image">
      <Flex height="inherit" justifyContent="center" alignItems="center">
        <Image src={AkruLogo} height="307px" />
      </Flex>
    </Box>
  );
};

export default ComingSoon;
