import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className="bg-image-alts">
        <Box
          height="fill-available"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 5.29%, #000 100%);"
        >
          <Box className={isXLScreen ? "container" : "container-responsive"}>
            <Flex
              height="1002px"
              paddingBottom={{ base: "50px", xl: "100px" }}
              justifyContent="end"
              flexDirection="column"
            >
              <Flex
                flexDirection="column"
                alignItems="start"
                justifyContent="end"
                paddingTop={{ base: "40px", xl: "80px" }}
                paddingBottom={{ base: "40px", xl: "30px" }}
              >
                <Text
                  textAlign="start"
                  color=" #FFF"
                  fontSize={{ base: "30px", xl: " 48px" }}
                  fontStyle="normal"
                  fontWeight={{ base: "600", xl: " 500" }}
                  lineHeight={{ base: "40px", xl: "70px" }}
                >
                  AKRU Alts. Custom Private Markets Platform
                </Text>
                <Text
                  textAlign="start"
                  color=" #FFF"
                  fontSize={{ base: "14px", xl: " 20px" }}
                  fontStyle="normal"
                  fontWeight=" 400"
                  lineHeight={{ base: "30px", xl: "35px" }}
                >
                  Innovative, Personalized Asset Management for the Modern
                  Investor
                </Text>
              </Flex>
              <Box>
                <Text
                  textAlign="start"
                  color=" #FFF"
                  fontSize={{ base: "14px", xl: " 24px" }}
                  fontStyle="normal"
                  fontWeight=" 400"
                  lineHeight={{ base: "30px", xl: "40px" }}
                >
                  Elevating finance with a focus on customization, cost
                  efficiency, and enhanced security. Experience a new level of
                  transparency in asset management. Our platform goes beyond
                  simple asset digitization, fostering trust and efficiency in a
                  more inclusive financial landscape.
                </Text>
              </Box>
              <Box paddingTop="30px">
                <Link href="/learn-more-alts">
                  <Button
                    borderRadius="5px"
                    fontSize={{ base: "14px", xl: "16px" }}
                    color="white"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                    paddingX="26px"
                    paddingY="13px"
                    backgroundColor="#FD8534"
                    _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
                    colorScheme="white"
                  >
                    Learn More
                  </Button>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
