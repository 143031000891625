import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import Fade from "react-reveal/Fade";
const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className="bg-image-aboutUs"></Box>
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          paddingBottom="100px"
          justifyContent="start"
          flexDirection="column"
        >
          <Flex
            flexDirection="column"
            alignItems="start"
            justifyContent="end"
            paddingTop={{ base: "40px", xl: "80px" }}
          >
            <Fade left>
              <Text
                textAlign="start"
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
                paddingBottom="30px"
              >
                About Us
              </Text>
            </Fade>
            <Fade right>
              <Text
                paddingBottom="30px"
                textAlign="start"
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                At AKRU On-Fi, our mission is to redefine the private markets
                landscape through innovative blockchain technology and natural
                language processing. We are committed to ushering in a new era
                where finance is not only secure and transparent but also more
                efficient and inclusive.
              </Text>
            </Fade>
          </Flex>
          <Box>
            <Fade right>
              <Text
                textAlign="start"
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                By integrating cutting-edge solutions like AKRU IM, AKRU
                Servicing, and AKRU Admin, we aim to reshape trust and
                efficiency in finance, making it more accessible and reliable
                for all. This opens new avenues for cost reduction and enhanced
                security, ultimately redefining the essence of financial trust
                and efficiency.
              </Text>
            </Fade>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Banner;
