import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import DirectDocumentImage from "../../../../assets/images/documentAnalyzer/Rectangle 5809.png";
import DirectDocumentImage2 from "../../../../assets/images/documentAnalyzer/Group (2).png";
import Fade from "react-reveal/Fade";
const DirectDocument = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex paddingTop="100px" justifyContent="center">
          <Box
            backgroundImage={DirectDocumentImage}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            height={{ base: "700px", xl: "527px" }}
            width="100%"
            backgroundAttachment="fixed"
          >
            <Flex
              flexDirection="column"
              height="inherit"
              alignItems="center"
              justifyContent="center"
              paddingX={{ base: "10px", xl: "0px" }}
            >
              <Text
                width={{ base: "fit-content", xl: "770px" }}
                color="#FFF"
                textAlign="center"
                fontSize={{ base: "24px", xl: "36px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: " 500" }}
                lineHeight={{ base: "40px", xl: "50px" }}
              >
                Dive Deeper with our Exclusive Product Sheet
              </Text>
              <Text
                paddingTop="10px"
                width={{ base: "fit-content", xl: "685px" }}
                color="#FFF"
                textAlign="center"
                fontSize={{ base: "14px", xl: "16px" }}
                fontStyle="normal"
                fontWeight=" 500"
                lineHeight="30px"
              >
                Get an in-depth look at the AKRU Document Analyzer with our
                exclusive product sheet. Our product sheet delves deeper into
                how our technology enhances and optimizes investment oversight
                offering a clearer path for strategic decision-making. Download
                our product sheet today to unlock a new level of efficiency and
                precision in your document analysis processes
              </Text>
              <Link href="/download-page-data-analyzer">
                <Button
                  marginTop="40px"
                  width="inherit"
                  borderRadius="5px"
                  fontSize={{ base: "14px", xl: "16px" }}
                  color="white"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  paddingX="26px"
                  paddingY="13px"
                  backgroundColor="#FD8534"
                  _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
                  colorScheme="white"
                >
                  Download Now
                </Button>
              </Link>
            </Flex>
          </Box>
        </Flex>
        <Flex
          flexDirection={{ base: "column", xl: "row" }}
          paddingTop="0px"
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Fade left>
            <Box
              paddingTop={{ base: "50px", xl: "100px" }}
              paddingBottom="100px"
            >
              <Text
                width={{ base: "fit-content", xl: "547px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Direct Document Engagement
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Engage and interact directly with documents, extracting specific
                insights to optimize investment oversight, allowing you to
                obtain targeted information and reducing the time and effort
                required for decision-making processes.
              </Text>
            </Box>
          </Fade>
          <Flex
            paddingTop={{ base: "50px", xl: "0px" }}
            paddingBottom="100px"
            position="relative"
            width="fill-available"
            justifyContent="center"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Fade right>
              <Image
                h="150px"
                src={DirectDocumentImage2}
              />
            </Fade>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default DirectDocument;
