import {
  Box,
  Divider,
  HStack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

const KeyBenifits = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box backgroundColor="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <VStack paddingTop="80px">
          <Text
            color=" #000"
            fontSize={{ base: "34", xl: " 48px" }}
            fontStyle="normal"
            fontWeight=" 500"
            lineHeight="65px"
          >
            Key Benefits
          </Text>
          <HStack
            gap="40px"
            flexDirection={{ base: "column", xl: "row" }}
            justifyContent="space-between"
            width="100%"
            flexWrap="wrap"
            paddingY="80px"
          >
            {/* 1-3 */}
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  01
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  width={{ base: "fit-content", xl: "258px" }}
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Comprehensive Analysis of Key Financial Documents
                </Text>
              </Box>
            </HStack>
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  02
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  width={{ base: "fit-content", xl: "209px" }}
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Clear Identification of Investor Share Classes
                </Text>
              </Box>
            </HStack>
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  03
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  width={{ base: "fit-content", xl: "211px" }}
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Waterfall Calculations from Documents
                </Text>
              </Box>
            </HStack>
            {/* 4-5 */}
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  04
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  width={{ base: "fit-content", xl: "270px" }}
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Direct Engagement with Documents for Targeted Insights
                </Text>
              </Box>
            </HStack>
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  05
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  width={{ base: "fit-content", xl: "276px" }}
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Streamlined Decision-Making Processes
                </Text>
              </Box>
            </HStack>
            <HStack
              alignItems="center"
              width={{ base: "100%", xl: "30%" }}
            ></HStack>
          </HStack>
          <Text
            paddingBottom="80px"
            color=" #666"
            fontSize={{ base: "18", xl: " 24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            By choosing AKRU Admin, you're not just adopting a platform; you're
            embracing a future where fund administration is defined by
            efficiency, transparency, and strategic insight.
          </Text>
          <Divider border="1px solid #ccc" />
        </VStack>
      </Box>
    </Box>
  );
};

export default KeyBenifits;
