import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import AdvantageImage1 from "../../../../assets/images/documentAnalyzer/Group.png";
import AdvantageImage2 from "../../../../assets/images/documentAnalyzer/Rectangle 5808.png";
import AdvantageImage3 from "../../../../assets/images/documentAnalyzer/Group (1).png";
import AdvantageImage4 from "../../../../assets/images/documentAnalyzer/Group 3894.png";
import Fade from "react-reveal/Fade";
const DocumentAnalyzerSub = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box bg="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        {/* section-1 */}
        <Flex
          paddingTop={{ base: "50px", xl: "100px" }}
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Box width={{ base: "100%", xl: "80%" }}>
            <Fade left>
              <Text
                width={{ base: "fit-content", xl: "611px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Comprehensive Document Analysis
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "730px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Seamlessly convertS key documents like asset agreements,
                security contracts, credit documents, and loan agreements into
                actionable data, redefining document analysis for a
                comprehensive understanding of your investments.
              </Text>
            </Fade>
          </Box>
          <Flex
            paddingTop={{ base: "50px", xl: "0px" }}
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
            width="100%"
            justifyContent="center"
          >
            <Fade right>
              <Image
                h="150px"
                src={AdvantageImage1}
              />
            </Fade>
          </Flex>
        </Flex>
        <Flex justifyContent="center" position="relative" paddingY="100px">
          <Box
            backgroundImage={AdvantageImage2}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            height={{ base: "700px", xl: "527px" }}
            width="100%"
            backgroundAttachment="fixed"
          >
            <Flex
              flexDirection="column"
              height="inherit"
              alignItems="center"
              justifyContent="center"
              paddingX={{ base: "10px", xl: "0px" }}
            >
              <Text
                width={{ base: "fit-content", xl: "684px" }}
                color="#FFF"
                textAlign="center"
                fontSize={{ base: "24px", xl: "36px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: " 500" }}
                lineHeight={{ base: "40px", xl: "50px" }}
              >
                Engage with Our Experts
              </Text>
              <Text
                paddingTop="10px"
                width={{ base: "fit-content", xl: "685px" }}
                color="#FFF"
                textAlign="center"
                fontSize={{ base: "14px", xl: "16px" }}
                fontStyle="normal"
                fontWeight=" 500"
                lineHeight="30px"
              >
                Discover the transformative impact of our advanced AI-NLP-LLM
                technology on your investment oversight and document handling
                with our expert guidance. Engage with our experts today to see
                how the AKRU Document Analyzer can empower your strategic
                decisions.
              </Text>
              <Link href="/talk-to-us-data-analyzer">
                <Button
                  marginTop="40px"
                  width="inherit"
                  borderRadius="5px"
                  fontSize={{ base: "14px", xl: "16px" }}
                  color="white"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  paddingX="26px"
                  paddingY="13px"
                  backgroundColor="#FD8534"
                  _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
                  colorScheme="white"
                >
                  Talk To Us
                </Button>
              </Link>
            </Flex>
          </Box>
        </Flex>
        {/* section-2 */}
        <Flex
          flexDirection={{ base: "column-reverse", xl: "row" }}
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Flex
            paddingTop={{ base: "50px", xl: "0px" }}
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
            width="100%"
            justifyContent="center"
          >
            <Fade left>
              <Image
                h="150px"
                src={AdvantageImage3}
              />
            </Fade>
          </Flex>
          <Fade right>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "411px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Share Class Identification
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Identify different investor share classes to provide a clear
                understanding of the distribution of shares and ownership
                structure. This information is crucial for strategic
                decision-making and portfolio optimization.
              </Text>
            </Box>
          </Fade>
        </Flex>
        <Divider border="1px solid #ccc" marginY="120px" />
        {/* section-3 */}
        <Flex
          paddingBottom="80px"
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Box width={{ base: "100%", xl: "80%" }}>
            <Fade left>
              <Text
                width={{ base: "fit-content", xl: "275px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Waterfall Calculations
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "730px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Perform waterfall calculations directly from documents. This
                feature facilitates a quick and accurate assessment of
                distribution priorities and fund flows, streamlining the
                financial management aspect of investments.
              </Text>
            </Fade>
          </Box>
          <Flex
            paddingTop={{ base: "50px", xl: "0px" }}
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
            width="100%"
            justifyContent="center"
          >
            <Fade right>
              <Image
                h="150px"
                src={AdvantageImage4}
              />
            </Fade>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default DocumentAnalyzerSub;
