import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import Fade from "react-reveal/Fade";
import EffectiveInvestorImage2 from "../../../../assets/images/AkruInvestorManagment/Group 3886.png";
import EffectiveInvestorImage3 from "../../../../assets/images/AkruInvestorManagment/Group 3888.png";

const EffectiveInvestor = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        {/* section-2 */}
        <Flex
          flexDirection={{ base: "column-reverse", xl: "row" }}
          paddingTop={{ base: "60px", xl: "120px" }}
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Box
            paddingTop={{ base: "50px", xl: "0px" }}
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={EffectiveInvestorImage2}
            />
          </Box>
          <Fade right>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "493px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Investor Engagement Insights
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Comprehensive tracking of file interactions for deeper
                understanding of document use and investor engagement.
              </Text>
            </Box>
          </Fade>
        </Flex>
        {/* section-3 */}
        <Flex
          paddingTop={{ base: "60px", xl: "120px" }}
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "start" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Box>
            <Fade left>
              <Text
                width={{ base: "fit-content", xl: "435px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Advanced Security Protocols
              </Text>
              <Text
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Combine two-factor authentication and encryption, meeting SOC 1
                and SOC 2 standards for unrivaled data security.
              </Text>
            </Fade>
          </Box>

          <Box
            paddingTop={{ base: "50px", xl: "0px" }}
            paddingBottom="100px"
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={EffectiveInvestorImage3}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default EffectiveInvestor;
