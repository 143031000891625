import { Global } from "@emotion/react";

const Fonts = () => (
  <Global
    styles={`
      @font-face {
        font-family: 'Cera Pro Regular';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: url('./fonts/Cera_Pro_Regular.otf') format('otf'), url('./fonts/Cera_Pro_Regular.woff') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'Cera Pro Thin';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url('./fonts/Cera_Pro_Thin.otf') format('otf'), url('./fonts/Cera_Pro_Thin.woff') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'Cera Pro Light';
        font-style: normal;
        font-weight: 100;
        font-display: swap;
        src: url('./fonts/Cera_Pro_Light.otf') format('otf'), url('./fonts/Cera_Pro_Light.woff') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'Cera Pro Medium';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('./fonts/Cera_Pro_Medium.otf') format('otf'), url('./fonts/Cera_Pro_Medium.woff') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      @font-face {
        font-family: 'Cera Pro Bold';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('./fonts/Cera_Pro_Bold.otf') format('otf'), url('./fonts/Cera_Pro_Bold.woff') format('otf');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
      `}
  />
);

export default Fonts;
