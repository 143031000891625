import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../../../components/header";
import Banner from "./components/banner";
import TalkToUsSub from "./components/talkToUsSub";
import Footer from "../../../../components/footer";
const TalkToUsAonFi = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <TalkToUsSub />
      <Footer />
    </Box>
  );
};

export default TalkToUsAonFi;
