import {
  Box,
  Divider,
  HStack,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

const KeyBenifits = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box backgroundColor="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <VStack paddingTop="80px">
          <Text
            color=" #000"
            fontSize={{ base: "34", xl: " 48px" }}
            fontStyle="normal"
            fontWeight=" 500"
            lineHeight="65px"
          >
            Key Benefits
          </Text>
          <HStack
            gap="40px"
            flexDirection={{ base: "column", xl: "row" }}
            justifyContent="space-between"
            width="100%"
            flexWrap="wrap"
            paddingY="80px"
          >
            {/* 1-3 */}
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  01
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  // width="177px"
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Streamlined Credit Management
                </Text>
              </Box>
            </HStack>
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  02
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  // width="200px"
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Smart Contract Automation
                </Text>
              </Box>
            </HStack>
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  03
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  // width="214px"
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Enhanced Efficiency
                </Text>
              </Box>
            </HStack>
            {/* 4-6 */}
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  04
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  // width="154px"
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Simplified Back-Office Operations
                </Text>
              </Box>
            </HStack>
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  05
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  // width="147px"
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Transparent and Trustworthy Transaction Insights
                </Text>
              </Box>
            </HStack>
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  06
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  // width="107px"
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Compliance-Focused Servicing
                </Text>
              </Box>
            </HStack>
            {/* 7-9 */}
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  07
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  // width="190px"
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Comprehensive Deal Structure Management
                </Text>
              </Box>
            </HStack>
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  08
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  // width="191px"
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Optimized Capital Flow Tracking
                </Text>
              </Box>
            </HStack>
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  09
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  // width="172px"
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Effective Capital Flow Management
                </Text>
              </Box>
            </HStack>
            {/* 10 */}
            <HStack alignItems="center" width={{ base: "100%", xl: "30%" }}>
              <Box
                backgroundColor="#FD8534"
                borderRadius="5px"
                paddingY="15px"
                paddingX="20px"
              >
                <Text
                  color="#FFF"
                  fontSize="24px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  10
                </Text>
              </Box>
              <Box paddingLeft="30px">
                <Text
                  // width="132px"
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="normal"
                >
                  Precise and Efficient Processing
                </Text>
              </Box>
            </HStack>
          </HStack>
          <Text
            paddingBottom="80px"
            color=" #666"
            fontSize={{ base: "18", xl: " 24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            At the core of AKRU Servicing is our commitment to simplifying what
            has traditionally been a complex and error-prone process. Step into
            an era of simplified credit servicing with AKRU Servicing, where
            efficiency, accuracy, and compliance are at the forefront.
          </Text>
          <Divider border="1px solid #ccc" />
        </VStack>
      </Box>
    </Box>
  );
};

export default KeyBenifits;
