import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import InsightCard1 from "../../../../assets/images/blog/Rectangle 5832.png";
import InsightCard2 from "../../../../assets/images/blog/Rectangle 5836.png";
import InsightCard3 from "../../../../assets/images/blog/Rectangle 5839.png";
import { useNavigate } from "react-router-dom";
const LastestInsight = () => {
  const history = useNavigate();
  const [activeCard, setActiveCard] = useState(null);

  const isXLScreen = useBreakpointValue({ base: false, xl: true });

  const handleClick = (cardNumber) => {
    setActiveCard(cardNumber === activeCard ? null : cardNumber);
  };
  const cardData = [
    {
      imageSrc: InsightCard1,
      title: "How Smart Contracts are ...",
      description:
        "The financial landscape, particularly in private markets, is witnessing a transformative shift with the integration of smart contracts.",
    },
    {
      imageSrc: InsightCard2,
      title: "The Role of Blockchain in...",
      description:
        "In the complex and often opaque realm of private markets, transparency remains a perennial challenge. ",
    },
    {
      imageSrc: InsightCard3,
      title: "Beyond Traditional Investing...",
      description:
        "Till now, private markets have remained relatively inaccessible to a broader audience due to their complex nature and high entry .",
    },
  ];
  const handleBlogClicked = (ind) => {
    switch (ind) {
      case 0:
        history("/blog-details-sub2");
        window.scroll(0,0)
        break;
      case 1:
        history("/blog-details-sub3");
        window.scroll(0,0)
        break;
      case 2:
        history("/blog-details-sub4");
        window.scroll(0,0)
        break;

      default:
        break;
    }
  };

  return (
    <Box backgroundColor="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <HStack
          flexWrap="wrap"
          paddingY="100px"
          justifyContent={{ base: "center", xl: "space-between" }}
          gap="20px"
          sx={{
            overflowX: "scroll",
            "::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {cardData.map((card, index) => (
            <Box
              onClick={() => handleBlogClicked(index)}
              key={index}
              onMouseEnter={() => handleClick(index)}
              onMouseLeave={() => handleClick(null)}
              cursor="pointer"
              backgroundColor="White"
              border="1px solid #ccc"
              borderRadius="8px"
              width={{ base: "fill-available", xl: "350px" }}
            >
              <Image width="fill-available" src={card.imageSrc} />
              <Box paddingTop="20px" paddingX="20px">
                <Text
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight={{ base: "600", md: "500" }}
                  lineHeight="28px"
                >
                  {card.title}
                </Text>
                <Text
                  paddingTop="10px"
                  color="#666"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight={{ base: "20px", md: "28px" }}
                >
                  {card.description}
                </Text>
              </Box>

              <Divider paddingTop="30px" />
              <Flex
                paddingY="17px"
                paddingX="24px"
                justifyContent="space-between"
                alignItems="center"
                style={{ cursor: "pointer" }}
              >
                <Text
                  cursor="pointer"
                  color={activeCard === index ? "#FD8534" : "#000"}
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight={{ base: "600", md: "500" }}
                  lineHeight="normal"
                >
                  Read More
                </Text>
                <Box>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="11"
                    viewBox="0 0 22 11"
                    fill={activeCard === index ? "#FD8534" : "black"}
                  >
                    <path
                      d="M0.71035 4.33374H18.8953L15.8461 1.281C15.7297 1.14503 15.6688 0.970126 15.6757 0.791243C15.6826 0.612361 15.7568 0.442673 15.8834 0.31609C16.01 0.189506 16.1797 0.11535 16.3585 0.10844C16.5374 0.101531 16.7123 0.162377 16.8483 0.278819L21.1129 4.54342C21.1965 4.62549 21.2591 4.72642 21.2956 4.83773C21.3321 4.94904 21.3413 5.06748 21.3226 5.18311C21.2905 5.31695 21.2229 5.43969 21.1271 5.53849L16.8625 9.80309C16.7988 9.87749 16.7204 9.93792 16.6322 9.98058C16.544 10.0232 16.448 10.0472 16.3501 10.051C16.2522 10.0548 16.1546 10.0383 16.0634 10.0026C15.9722 9.96682 15.8893 9.91262 15.8201 9.84335C15.7508 9.77408 15.6966 9.69125 15.6609 9.60004C15.6251 9.50883 15.6086 9.41122 15.6124 9.31333C15.6162 9.21544 15.6402 9.1194 15.6828 9.03121C15.7255 8.94303 15.7859 8.86462 15.8603 8.80091L18.8953 5.75527H0.71035C0.521843 5.75527 0.341057 5.68039 0.207762 5.54709C0.0744667 5.4138 -0.000415802 5.23301 -0.000415802 5.04451C-0.000415802 4.856 0.0744667 4.67521 0.207762 4.54192C0.341057 4.40862 0.521843 4.33374 0.71035 4.33374Z"
                      fill={activeCard === index ? "#FD8534" : "black"}
                    />
                  </svg>
                </Box>
              </Flex>
            </Box>
          ))}
        </HStack>
      </Box>
    </Box>
  );
};

export default LastestInsight;
