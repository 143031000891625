import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

const ExploreTheFuture = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box backgroundColor="white">
      <Flex pos="relative">
        <Box
          className={isXLScreen ? "container" : "container-responsive"}
          paddingY="53px"
        >
          <Text
            width={{ base: "fit-content", xl: "549px" }}
            color=" #272828"
            fontSize={{ base: "24px", xl: "30px" }}
            fontStyle="normal"
            fontWeight={{ base: "600", xl: "500" }}
            lineHeight={{ base: "40px", xl: " 50px" }}
          >
            Explore the Future of Fund Administration with AKRU Admin
          </Text>
          <Text
            marginBottom={{ base: "300px", xl: "0px" }}
            paddingTop="10px"
            width={{ base: "fit-content", xl: "696px" }}
            color=" #666"
            fontSize={{ base: "12px", xl: "16px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight={{ base: "20px", xl: " 27px" }}
          >
            Are you prepared to transform your fund administration experience?
            At AKRU, our dedicated team is ready to product sheet you through
            every step, answering any questions you may have and ensuring a
            seamless transition into the future of finance. Connect with us
            today, and let's redefine financial excellence together.
          </Text>
        </Box>
        <Box
          className="finance-firm-bg "
          width={{ base: "80%", xl: "36%" }}
          height={{ base: "", xl: "325px" }}
          pos="absolute"
        >
          <Flex height="inherit" alignItems="center" justifyContent="center">
            <Link href="/talk-to-us">
              <Button
                borderRadius="5px"
                fontSize="18px"
                color="#FD8534"
                _hover={{ color: "white", backgroundColor: "#FD9D5D" }}
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                paddingX="26px"
                paddingY="13px"
                backgroundColor="white"
                colorScheme="white"
              >
                Talk To Us
              </Button>
            </Link>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default ExploreTheFuture;
