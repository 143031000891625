import React, { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  HStack,
  Image,
  Img,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import OnFiLogo from "../../assets/images/home/Group 3.webp";
import VectoLogo from "../../assets/images/home/Vector5.webp";
import Hamburger from "../../assets/images/home/Group (6).webp";

const Header = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  const [isActive1, setIsActive1] = useState(false);
  const [isActive2, setIsActive2] = useState(false);
  const [isActive3, setIsActive3] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleClick1 = () => {
    setIsActive1((prev) => !prev);
    setIsActive2(false);
    setIsActive3(false);
  };

  const handleClick2 = () => {
    setIsActive2((prev) => !prev);
    setIsActive1(false);
    setIsActive3(false);
  };

  const handleClick3 = () => {
    setIsActive3((prev) => !prev);
    setIsActive1(false);
    setIsActive2(false);
  };
  const handleHamburgerClick = () => {
    setDrawerOpen(!isDrawerOpen);
  };

  return (
    <>
      {/* main-header */}
      <Box
        zIndex="20"
        position="sticky"
        top="0"
        backgroundColor="white"
        w="100%"
      >
        <Flex
          className={isXLScreen ? "container" : "container-responsive"}
          justifyContent="space-between"
          alignItems="center"
          paddingY="15px"
        >
          {/* logo */}
          <Box>
            <Link href="/">
              <Img src={OnFiLogo} />
            </Link>
          </Box>
          {/* links-button */}
          <Flex display={{ base: "none", xl: "flex" }}>
            <HStack spacing="40px">
              <Link
                href="/"
                _hover={{
                  textDecoration: "none",
                }}
              >
                <Text
                  cursor="pointer"
                  onClick={handleClick1}
                  color={isActive1 ? "#FD8534" : "#666"}
                  fontSize="18px"
                  fontStyle=" normal"
                  fontWeight="500"
                  lineHeight="normal"
                >
                  Home
                </Text>
              </Link>
              <Menu>
                <MenuButton cursor="pointer" onClick={handleClick2}>
                  <Flex alignItems="center">
                    <Text
                      color={isActive2 ? "#FD8534" : "#666"}
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight="500"
                      lineHeight="normal"
                      paddingRight="7px"
                      margin="0px"
                    >
                      Solutions
                    </Text>
                    <Img
                      width="10px"
                      height="8px"
                      src={VectoLogo}
                      alt="Vector Logo"
                    />
                  </Flex>
                </MenuButton>
                <MenuList marginTop="10px">
                  <Link
                    href="/akru-alts"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem
                      fontSize="16px"
                      color="#666"
                      fontWeight="500"
                      _hover={{
                        color: "#FD8534",
                      }}
                    >
                      AKRU Alts. Custom Private Markets
                    </MenuItem>
                  </Link>

                  <Link
                    href="/akru-investor-managment"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem
                      fontSize="16px"
                      color="#666"
                      fontWeight="500"
                      _hover={{ color: "#FD8534" }}
                    >
                      AKRU IM
                    </MenuItem>
                  </Link>
                  <Link
                    href="/akru-admin"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem
                      fontSize="16px"
                      color="#666"
                      fontWeight="500"
                      _hover={{ color: "#FD8534" }}
                    >
                      AKRU Admin
                    </MenuItem>
                  </Link>
                  <Link
                    href="/akru-servicing"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem
                      fontSize="16px"
                      color="#666"
                      fontWeight="500"
                      _hover={{ color: "#FD8534" }}
                    >
                      AKRU Servicing
                    </MenuItem>
                  </Link>
                  <Link
                    href="/a-onfi-network"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem
                      fontSize="16px"
                      color="#666"
                      fontWeight="500"
                      _hover={{ color: "#FD8534" }}
                    >
                      A On-Fi Network
                    </MenuItem>
                  </Link>
                  <Link
                    href="/document-analyzer"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem
                      fontSize="16px"
                      color="#666"
                      fontWeight="500"
                      _hover={{ color: "#FD8534" }}
                    >
                      AKRU Document Analyzer
                    </MenuItem>
                  </Link>
                </MenuList>
              </Menu>
              <Menu>
                <MenuButton cursor="pointer" onClick={handleClick3}>
                  <Flex alignItems="center">
                    <Text
                      color={isActive3 ? "#FD8534" : "#666"}
                      fontSize="18px"
                      fontStyle="normal"
                      fontWeight="500"
                      lineHeight="normal"
                      paddingRight="7px"
                      margin="0px"
                    >
                      Resources
                    </Text>
                    <Img
                      width="10px"
                      height="8px"
                      src={VectoLogo}
                      alt="Vector Logo"
                    />
                  </Flex>
                </MenuButton>
                <MenuList marginTop="10px">
                  {/* <Link
                  href="/events"
                  _hover={{
                    textDecoration: "none",
                  }} 
                > */}

                  {/* </Link> */}
                  <Link
                    href="/about-us"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem
                      fontSize="16px"
                      color="#666"
                      fontWeight="500"
                      _hover={{ color: "#FD8534" }}
                    >
                      About Us
                    </MenuItem>
                  </Link>
                  <Link
                    href="/blog"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem
                      fontSize="16px"
                      color="#666"
                      fontWeight="500"
                      _hover={{ color: "#FD8534" }}
                    >
                      Blogs
                    </MenuItem>
                  </Link>

                  {/* <MenuItem
                    fontSize="16px"
                    color="#666"
                    fontWeight="500"
                    _hover={{ color: "#FD8534" }}
                  >
                    Events
                  </MenuItem> */}
                  <Link
                    href="/contact-us"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <MenuItem
                      fontSize="16px"
                      color="#666"
                      fontWeight="500"
                      _hover={{ color: "#FD8534" }}
                    >
                      Contact Us
                    </MenuItem>
                  </Link>
                  {/* <MenuItem
                  fontSize="16px"
                  color="#666"
                  fontWeight="500"
                  _hover={{ color: "#FD8534" }}
                >
                  Careers
                </MenuItem> */}
                </MenuList>
              </Menu>
            </HStack>
            {/* <Box>
            <Button
              borderRadius="5px"
              fontSize="16px"
              color="white"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
              paddingX="26px"
              paddingY="13px"
              backgroundColor="#FD8534"
              _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
              colorScheme="white"
            >
              Get Started
            </Button>
          </Box> */}
          </Flex>
          {/* responsive */}
          <>
            {/* Hamburger icon */}
            <Box
              display={{ base: "flex", xl: "none" }}
              onClick={handleHamburgerClick}
            >
              <Image src={Hamburger} />
            </Box>

            {/* Drawer */}
            <Drawer
              placement="right"
              onClose={() => setDrawerOpen(false)}
              isOpen={isDrawerOpen}
            >
              <DrawerOverlay />
              <DrawerContent>
                <DrawerCloseButton />
                <DrawerHeader>
                  <Link
                    href="/"
                    _hover={{
                      textDecoration: "none",
                    }}
                  >
                    <Img src={OnFiLogo} alt="akru logo" height="30px" />
                  </Link>
                </DrawerHeader>
                <DrawerBody>
                  <VStack
                    height="-webkit-fill-available"
                    alignItems="start"
                    spacing="20px"
                  >
                    <Link
                      href="/"
                      _hover={{
                        textDecoration: "none",
                      }}
                    >
                      <Text
                        cursor="pointer"
                        onClick={handleClick1}
                        color={isActive1 ? "#FD8534" : "#666"}
                        fontSize="18px"
                        fontStyle="normal"
                        fontWeight="500"
                        lineHeight="normal"
                      >
                        Home
                      </Text>
                    </Link>
                    <Accordion border="0px solid transparent" allowToggle>
                      <AccordionItem background="transparent" minWidth="280px">
                        <Text>
                          <AccordionButton p="0px">
                            <Text
                              color={isActive2 ? "#FD8534" : "#666"}
                              fontSize="18px"
                              fontStyle="normal"
                              fontWeight="500"
                              lineHeight="normal"
                              margin="0px"
                              as="span"
                              flex="1"
                              textAlign="left"
                            >
                              Solutions{" "}
                            </Text>
                            <AccordionIcon />
                          </AccordionButton>
                        </Text>
                        <AccordionPanel paddingLeft="5px" paddingTop="20px">
                          <VStack
                            spacing="15px"
                            justifyContent="start"
                            alignItems="start"
                          >
                            <Link
                              href="/akru-alts"
                              _hover={{
                                textDecoration: "none",
                              }}
                            >
                              <Text
                                fontSize="14px"
                                color="#666"
                                fontWeight="500"
                                _hover={{ color: "#FD8534" }}
                              >
                                {" "}
                                AKRU Alts. Custom Private Markets
                              </Text>
                            </Link>
                            <Link
                              href="/akru-investor-managment"
                              _hover={{
                                textDecoration: "none",
                              }}
                            >
                              <Text
                                fontSize="14px"
                                color="#666"
                                fontWeight="500"
                                _hover={{ color: "#FD8534" }}
                              >
                                {" "}
                                AKRU IM
                              </Text>
                            </Link>
                            <Link
                              href="/akru-admin"
                              _hover={{
                                textDecoration: "none",
                              }}
                            >
                              <Text
                                fontSize="14px"
                                color="#666"
                                fontWeight="500"
                                _hover={{ color: "#FD8534" }}
                              >
                                {" "}
                                AKRU Admin
                              </Text>
                            </Link>
                            <Link
                              href="/akru-servicing"
                              _hover={{
                                textDecoration: "none",
                              }}
                            >
                              <Text
                                fontSize="14px"
                                color="#666"
                                fontWeight="500"
                                _hover={{ color: "#FD8534" }}
                              >
                                {" "}
                                AKRU Servicing
                              </Text>
                            </Link>
                            <Link
                              href="/a-onfi-network"
                              _hover={{
                                textDecoration: "none",
                              }}
                            >
                              <Text
                                fontSize="14px"
                                color="#666"
                                fontWeight="500"
                                _hover={{ color: "#FD8534" }}
                              >
                                {" "}
                                A On-Fi Network
                              </Text>
                            </Link>
                            <Link
                              href="/document-analyzer"
                              _hover={{
                                textDecoration: "none",
                              }}
                            >
                              <Text
                                fontSize="14px"
                                color="#666"
                                fontWeight="500"
                                _hover={{ color: "#FD8534" }}
                              >
                                {" "}
                                AKRU Document Analyzer
                              </Text>
                            </Link>
                          </VStack>
                        </AccordionPanel>
                      </AccordionItem>
                      <AccordionItem
                        background="transparent"
                        minWidth="280px"
                        paddingTop="20px"
                      >
                        <Text>
                          <AccordionButton p="0px">
                            <Text
                              color={isActive2 ? "#FD8534" : "#666"}
                              fontSize="18px"
                              fontStyle="normal"
                              fontWeight="500"
                              lineHeight="normal"
                              margin="0px"
                              as="span"
                              flex="1"
                              textAlign="left"
                            >
                              Resources
                            </Text>
                            <AccordionIcon />
                          </AccordionButton>
                        </Text>
                        <AccordionPanel paddingLeft="5px" paddingTop="20px">
                          <VStack
                            spacing="15px"
                            justifyContent="start"
                            alignItems="start"
                          >
                            {/* <Link
                            href="/events"
                            _hover={{
                              textDecoration: "none",
                            }}
                          > */}

                            {/* </Link> */}
                            <Link
                              href="/about-us"
                              _hover={{
                                textDecoration: "none",
                              }}
                            >
                              <Text
                                fontSize="14px"
                                color="#666"
                                fontWeight="500"
                                _hover={{ color: "#FD8534" }}
                              >
                                {" "}
                                About Us
                              </Text>
                            </Link>
                            <Link
                              href="/blog"
                              _hover={{
                                textDecoration: "none",
                              }}
                            >
                              <Text
                                fontSize="14px"
                                color="#666"
                                fontWeight="500"
                                _hover={{ color: "#FD8534" }}
                              >
                                {" "}
                                Blogs
                              </Text>
                            </Link>

                            {/* <Text
                              fontSize="14px"
                              color="#666"
                              fontWeight="500"
                              _hover={{ color: "#FD8534" }}
                            >
                              {" "}
                              Events
                            </Text> */}
                            <Link
                              href="/contact-us"
                              _hover={{
                                textDecoration: "none",
                              }}
                            >
                              <Text
                                fontSize="14px"
                                color="#666"
                                fontWeight="500"
                                _hover={{ color: "#FD8534" }}
                              >
                                {" "}
                                Contact Us
                              </Text>
                            </Link>
                          </VStack>
                        </AccordionPanel>
                      </AccordionItem>
                    </Accordion>

                    {/* <Flex width="100%" height="inherit" alignItems="end">
                    <Button
                      width="inherit"
                      borderRadius="5px"
                      fontSize="16px"
                      color="white"
                      fontStyle="normal"
                      fontWeight="500"
                      lineHeight="normal"
                      paddingX="26px"
                      paddingY="13px"
                      backgroundColor="#FD8534"
                      _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
                      colorScheme="white"
                    >
                      Get Started
                    </Button>
                  </Flex> */}
                  </VStack>
                </DrawerBody>
              </DrawerContent>
            </Drawer>
          </>
        </Flex>
      </Box>
    </>
  );
};

export default Header;
