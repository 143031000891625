import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/header";
import Banner from "./components/banner";
import RecivePayments from "./components/recivePayment";
// import Clients from "./components/clients";
import Services from "./components/services";
import PaymentMethods from "./components/paymentMethods";
import Process from "./components/process";
import StartBusiness from "./components/startBusiness";
import FinanceFirm from "./components/financeFirm";
import LastestInsight from "./components/latestInsight";
import Footer from "../../components/footer";

const Home = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <RecivePayments />
      {/* <Clients /> */}
      <Services />
      <PaymentMethods />
      <Process />
      <StartBusiness />
      <FinanceFirm />
      <LastestInsight />
      <Footer />
    </Box>
  );
};

export default Home;
