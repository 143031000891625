import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/header";
import Banner from "./components/banner";
import CrossChain from "./components/crossChain";
import PrivateSubnet from "./components/privateSubnet";
import InnovativePlatform from "./components/innovativePlatform";
import Footer from "../../components/footer";

const OnFiNetwork = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <CrossChain />
      <PrivateSubnet />
      <InnovativePlatform />
      <Footer />
    </Box>
  );
};

export default OnFiNetwork;
