import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import UnmatchedImage1 from "../../../../assets/images/akruAlts/Rectangle 5809.png";
import UnmatchedImage2 from "../../../../assets/images/akruAlts/Group (1).png";
import UnmatchedImage3 from "../../../../assets/images/akruAlts/Group (2).png";
import UnmatchedImage4 from "../../../../assets/images/akruAlts/Group (3).png";
import Fade from "react-reveal/Fade";
const UnmatchedTransparency = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          paddingTop={{ base: "40px", xl: "100px" }}
          justifyContent="center"
          bottom={{ base: "60px", sm: "100px", md: "150px", xl: "250px" }}
        >
          <Box
            backgroundImage={UnmatchedImage1}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            height={{ base: "700px", xl: "527px" }}
            width="100%"
            backgroundAttachment="fixed"
          >
            <Flex
              flexDirection="column"
              height="inherit"
              alignItems="center"
              justifyContent="center"
              paddingX={{ base: "10px", xl: "0px" }}
            >
              <Text
                width={{ base: "fit-content", xl: "685px" }}
                color="#FFF"
                textAlign="center"
                fontSize={{ base: "24px", xl: "36px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: " 500" }}
                lineHeight={{ base: "40px", xl: "50px" }}
              >
                Download our Exclusive Product Sheet
              </Text>
              <Text
                paddingTop="10px"
                width={{ base: "fit-content", xl: "685px" }}
                color="#FFF"
                textAlign="center"
                fontSize={{ base: "14px", xl: "16px" }}
                fontStyle="normal"
                fontWeight=" 500"
                lineHeight="30px"
              >
                Our exclusive product sheet offers an insightful glimpse into
                the future of personalized asset management. Discover how our
                platform stands apart in providing a customized, secure, and
                efficient investment solution. Your journey to innovative,
                customized asset management starts here.
              </Text>
              <Link href="/download-alts-sub">
                <Button
                  marginTop="40px"
                  width="inherit"
                  borderRadius="5px"
                  fontSize={{ base: "14px", xl: "16px" }}
                  color="white"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  paddingX="26px"
                  paddingY="13px"
                  backgroundColor="#FD8534"
                  _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
                  colorScheme="white"
                >
                  Download Now
                </Button>
              </Link>
            </Flex>
          </Box>
        </Flex>
        {/* section-1 */}
        <HStack
          gap="120px"
          paddingTop="100px"
          justifyContent="space-evenly"
          alignItems="center"
          flexDirection={{ base: "column", xl: "row" }}
        >
          {" "}
          <Flex
            justifyContent="center"
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Fade left>
              <Image h="150px" src={UnmatchedImage2} />
            </Fade>
          </Flex>
          <Fade right>
            <Box
              w={{ base: "100%", xl: "70%" }}
              paddingTop={{ base: "50px", xl: "0px" }}
            >
              <Text
                width={{ base: "fit-content", xl: "300px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Unmatched Transparency
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "620px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Obtain immediate, detailed insights into your assets and
                transactions through blockchain technology, fostering greater
                trust and confidence.
              </Text>
            </Box>
          </Fade>
        </HStack>
        <Divider
          border="1px solid #CCC"
          marginY={{ base: "60px", xl: "120px" }}
        />
        {/* section-2 */}
        <Flex
          flexDirection={{ base: "column-reverse", xl: "row" }}
          paddingTop="0px"
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Fade left>
            <Box paddingTop={{ base: "50px", xl: "0px" }}>
              <Text
                width={{ base: "fit-content", xl: "547px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Efficient Automation via Smart Contracts
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Streamline transactions and reduce administrative tasks through
                the power of smart contracts.
              </Text>
            </Box>
          </Fade>
          <Flex
            paddingTop={{ base: "50px", xl: "0px" }}
            position="relative"
            width="fill-available"
            justifyContent="center"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Fade right>
              <Image
                h={{ base: "130px", xl: "150px" }}
                src={UnmatchedImage3}
                transition="transform 0.3s ease-in-out"
              />
            </Fade>
          </Flex>
        </Flex>
        <Divider
          border="1px solid #CCC"
          marginY={{ base: "60px", xl: "120px" }}
        />
        {/* section-3 */}
        <Flex
          paddingBottom="100px"
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Flex
            width="fill-available"
            justifyContent="center"
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Fade left>
              <Image
                h={{ base: "130px", xl: "150px" }}
                src={UnmatchedImage4}
                transition="transform 0.3s ease-in-out"
              />
            </Fade>
          </Flex>
          <Fade right>
            <Box paddingTop={{ base: "50px", xl: "0px" }}>
              <Text
                width={{ base: "fit-content", xl: "576px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Adherence to Regulatory Compliance
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "602px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Operate in a secure, compliant environment, ensuring peace of
                mind in your financial dealings with AKRU.
              </Text>
            </Box>
          </Fade>
        </Flex>
      </Box>
    </Box>
  );
};

export default UnmatchedTransparency;
