import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/header";
import Banner from "./components/banner";
import CreditServicing from "./components/creditServicing";
import Liquidity from "./components/liquidity";
import Efficient from "./components/efficient";
import Footer from "../../components/footer";

const AkruServicing = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <CreditServicing />
      <Liquidity />
      <Efficient />
      <Footer />
    </Box>
  );
};

export default AkruServicing;
