import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import AdvantageImage1 from "../../../../assets/images/akruAdmin/Group 3878.png";
import AdvantageImage2 from "../../../../assets/images/akruAdmin/Group 3899.png";
import FinanceImage3 from "../../../../assets/images/akruAdmin/Group 3877.png";
import ExploreTheFuture from "../exploreTheFuture";
import Fade from "react-reveal/Fade";
const Advantages = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box backgroundColor="#F9F9F9" paddingBottom="120px">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        {/* section-1 */}
        <Flex
          paddingTop="100px"
          justifyContent="space-between"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Fade left>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "466px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                AI-Driven Document Analysis
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Transform complex financial data into actionable insights with
                our AI and NLP-powered platform.
              </Text>
            </Box>
          </Fade>
          <Box
            paddingTop={{ base: "50px", xl: "0px" }}
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={AdvantageImage1}
            />
          </Box>
        </Flex>
        {/* section-2 */}
        <Flex
          flexDirection={{ base: "column-reverse", xl: "row" }}
          paddingTop={{ base: "60px", xl: "120px" }}
          paddingBottom={{ base: "80px", xl: "147px" }}
          justifyContent="space-between"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Box
            position="relative"
            paddingTop={{ base: "50px", xl: "0px" }}
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={AdvantageImage2}
            />
          </Box>
          <Fade right>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "411px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Advanced Capital Raising Tools
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Leverage AKRU's cutting-edge technology for streamlined investor
                engagement and effective capital management.
              </Text>
            </Box>
          </Fade>
        </Flex>
        {/* section-3 */}
        <Flex
          paddingBottom={{ base: "60px", xl: "100px" }}
          justifyContent="space-between"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Fade left>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "466px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Automated Waterfall Distributions
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Simplify fund disbursements with precision and reduce manual
                errors through our automated waterfall calculation system.
              </Text>
            </Box>
          </Fade>
          <Box
            position="relative"
            paddingY={{ base: "50px", xl: "0px" }}
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={FinanceImage3}
            />
          </Box>
        </Flex>
      </Box>
      <ExploreTheFuture />
    </Box>
  );
};

export default Advantages;
