import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import LiquidityImage1 from "../../../../assets/images/akruServicing/Rectangle 5808.png";
import LiquidityImage2 from "../../../../assets/images/akruServicing/Group 2.png";
import LiquidityImage3 from "../../../../assets/images/akruServicing/Group (1).png";
import Fade from "react-reveal/Fade";
const Liquidity = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box backgroundColor="#f9f9f9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          justifyContent="center"
          bottom={{ base: "60px", sm: "100px", md: "150px", xl: "250px" }}
        >
          <Box
            backgroundImage={LiquidityImage1}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            height={{ base: "700px", xl: "527px" }}
            width="100%"
            backgroundAttachment="fixed"
          >
            <Flex
              flexDirection="column"
              height="inherit"
              alignItems="center"
              justifyContent="center"
              paddingX={{ base: "10px", xl: "0px" }}
            >
              <Text
                width={{ base: "fit-content", xl: "633px" }}
                color="#FFF"
                textAlign="center"
                fontSize={{ base: "24px", xl: "36px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: " 500" }}
                lineHeight={{ base: "40px", xl: "50px" }}
              >
                Connect with AKRU Servicing Experts
              </Text>
              <Text
                paddingTop="10px"
                width={{ base: "fit-content", xl: "685px" }}
                color="#FFF"
                textAlign="center"
                fontSize={{ base: "14px", xl: "16px" }}
                fontStyle="normal"
                fontWeight=" 500"
                lineHeight="30px"
              >
                Step into the future of credit servicing with a personal touch
                from our AKRU Servicing experts. Our team is dedicated to here
                product sheet you through every step of the way. Connect with us
                today to transform your credit management experience and drive
                your success in the financial world.
              </Text>
              <Link href="/talk-to-us-servicing">
                <Button
                  marginTop="40px"
                  width="inherit"
                  borderRadius="5px"
                  fontSize={{ base: "14px", xl: "16px" }}
                  color="white"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  paddingX="26px"
                  paddingY="13px"
                  backgroundColor="#FD8534"
                  _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
                  colorScheme="white"
                >
                  Talk To Us
                </Button>
              </Link>
            </Flex>
          </Box>
        </Flex>
        {/* section-1 */}
        <Flex
          flexDirection={{ base: "column-reverse", xl: "row" }}
          paddingTop={{ base: "60px", xl: "120px" }}
          paddingBottom={{ base: "0px", xl: "0px" }}
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Flex
            justifyContent="center"
            paddingTop={{ base: "50px", xl: "0px" }}
            left={{ base: "-60px", xl: "0px" }}
            position="relative"
            inset="0px"
            width="fill-available"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Fade left>
              <Image h="150px" src={LiquidityImage2} />
            </Fade>
          </Flex>
          <Fade right>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "502px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Back-Office Operations Simplified
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Streamline operations with secure signatures, effortless capital
                calls, and KYC/AML processes. Address timestamp issues and
                prevent oversubscription.
              </Text>
            </Box>
          </Fade>
        </Flex>
        <Divider
          border="1px solid #CCC"
          marginY={{ base: "60px", xl: "120px" }}
        />
        {/* section-2 */}
        <Flex
          paddingBottom={{ base: "50px", xl: "120px" }}
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Fade left>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "296px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Transparent Operations
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "602px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Gain immediate, detailed insights into your credit transactions,
                fostering trust and confidence in our financial ecosystem.
              </Text>
            </Box>
          </Fade>
          <Flex
            position="relative"
            justifyContent="center"
            paddingTop={{ base: "50px", xl: "0px" }}
            width="fill-available"
            overflow="unset"
          >
            <Fade right>
              <Image h="150px"src={LiquidityImage3} />
            </Fade>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Liquidity;
