import { Box, Image, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import BlogDetailsImage1 from "../../../../assets/images/blogDetails/Rectangle 5808 (2).png";
import BlogDetailsImage2 from "../../../../assets/images/blogDetails/Rectangle 5841 (2).png";
const BlogDetailsSub1 = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });

  return (
    <Box className={isXLScreen ? "container" : "container-responsive"}>
      <Box paddingTop="100px">
        <Text
          color=" #666"
          fontSize={{ base: "16px", xl: "24px" }}
          fontStyle="normal"
          fontWeight="400"
          lineHeight="40px"
        >
          In the complex and often opaque realm of private markets, transparency
          remains a perennial challenge. Unlike their public market
          counterparts, private markets are not bound by stringent disclosure
          norms, leaving a gap in transparent and accessible information.
          However, the rise of blockchain technology offers a beacon of hope,
          promising to illuminate these traditionally shadowy corridors with its
          inherent transparency and security features.
        </Text>

        <Box paddingY="60px">
          <Image src={BlogDetailsImage1} />
        </Box>
        <Box paddingBottom="60px">
          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            The private market landscape is characterized by its inherent
            opacity and complexity. This lack of transparency stems primarily
            from the absence of standardized reporting and financial disclosures
            that are mandatory in public markets. In most jurisdictions, private
            companies are not legally required to publish the same level of
            detailed information as public companies, making them theoretically
            more opaque. However, those involved in private market deals often
            have direct access to management and company data, which provides a
            level of insight not typically.
            <Link
              color="blue"
              textDecor="underline"
              href="https://tfoco.com/en/insights/knowledge-hub/transparency-paradox-private-vs-public"
              target="_blank"
            >
              {" "}
              available
            </Link>
            in public company analysis​​.
          </Text>
          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            The challenges don't stop there. The private market's scattered
            nature{" "}
            <Link
              color="blue"
              textDecor="underline"
              href="https://www.linqto.com/blog/size-of-private-markets/"
              target="_blank"
            >
              {" "}
              further
            </Link>
            .complicates data collection efforts, with gaps often forming due to
            non-disclosure of sensitive information. Defining the boundaries
            between private and public entities adds another layer of
            complexity, especially as companies transition between statuses​​.
          </Text>

          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Enter blockchain technology, a game-changer in the quest for
            transparency in private markets. Blockchain's core feature,
            immutable data principles, ensures that data, once entered into the
            ledger, cannot be altered or removed, thereby maintaining a
            transparent and unchangeable record of transactions. This feature is
            particularly valuable in complex asset management chains, where
            transparency is crucial.
          </Text>
        </Box>

        <Box paddingY="60px">
          <Image src={BlogDetailsImage2} />
        </Box>
        <Box>
          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Moreover, blockchain facilitates secure and transparent data
            exchange across different networks through cross-chain
            interoperability. This capability not only enhances data security
            but also opens new avenues for investment opportunities in private
            markets, where secure and transparent transactions are paramount.
          </Text>
          <Text
            paddingTop="50px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Real-World Applications and Implications:
          </Text>
          <Text
            paddingTop="60px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Real-world applications of blockchain in private markets are already
            showing promising results. For instance, the use of smart contracts
            in blockchain for credit servicing automates and streamlines
            transaction processes, ensuring efficient and transparent
            record-keeping. This automation is particularly beneficial in
            private markets, where the traditional processes can be cumbersome
            and opaque. The future implications of blockchain technology in
            private markets are vast. Enhanced data management and reporting
            functions, enabled by blockchain, are becoming{" "}
            <Link
              color="blue"
              textDecor="underline"
              href="https://www.statestreet.com/us/en/asset-manager/insights/data-management-in-private-markets"
              target="_blank"
            >
              {" "}
              increasingly crucial
            </Link>
            for asset managers. Asset owners{" "}
            <Link
              color="blue"
              textDecor="underline"
              href="https://www.aima.org/journal/aima-journal---edition-128/article/rising-to-the-data-challenge-in-the-private-markets.html"
              target="_blank"
            >
              {" "}
              demand
            </Link>
            greater transparency on holdings, investment processes, costs, and
            sustainability criteria. The integration of blockchain can
            significantly alleviate these concerns by providing a transparent
            and efficient framework for data management and reporting​​​​.
          </Text>
          <Text
            paddingTop="60px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            In conclusion, blockchain technology stands as a powerful tool in
            the quest for transparency in private markets. Its ability to
            maintain immutable records, coupled with secure and efficient data
            exchange capabilities, addresses many of the traditional
            transparency challenges faced in these markets. As the technology
            continues to evolve and integrate more deeply into the private
            market sector, it promises to bring about a new era of openness and
            trust, benefiting investors, asset managers, and market regulators
            alike.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default BlogDetailsSub1;
