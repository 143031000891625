import { Box, Image, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import BlogDetailsImage1 from "../../../../assets/images/blogDetails/Rectangle 5808 (3).png";
import BlogDetailsImage2 from "../../../../assets/images/blogDetails/Rectangle 5841 (3).png";
const BlogDetailsSub1 = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });

  return (
    <Box className={isXLScreen ? "container" : "container-responsive"}>
      <Box paddingTop="100px">
        <Text
          color=" #666"
          fontSize={{ base: "16px", xl: "24px" }}
          fontStyle="normal"
          fontWeight="400"
          lineHeight="40px"
        >
          Till now, private markets have remained relatively inaccessible to a
          broader audience due to their complex nature and high entry barriers.
          However, the integration of cutting-edge technologies like blockchain
          and Artificial Intelligence (AI) is set to change this landscape.
          These technologies promise to democratize investments by enhancing
          transparency, efficiency, and security in private markets.
        </Text>
        <Text
          paddingTop="30px"
          color=" #666"
          fontSize={{ base: "16px", xl: "24px" }}
          fontStyle="normal"
          fontWeight="400"
          lineHeight="40px"
        >
          Blockchain technology is heralding a new era in private market
          investments. Its ability to offer a transparent, immutable record of
          transactions makes it an ideal solution for various challenges in
          these markets.
        </Text>
        <Text
          paddingTop="30px"
          color=" #666"
          fontSize={{ base: "16px", xl: "24px" }}
          fontStyle="normal"
          fontWeight="400"
          lineHeight="40px"
        >
          Key benefits of blockchain technology in the private markets sector
          include transparency and reliability, cost reduction, enhanced
          liquidity, and improved monitoring.
        </Text>
        <Box paddingY="60px">
          <Image src={BlogDetailsImage1} />
        </Box>
        <Box paddingBottom="60px">
          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Transparency and reliability are achieved through blockchain's
            immutable data structure, which provides more accurate and complete
            information crucial for investment{" "}
            <Link
              color="blue"
              textDecor="underline"
              href="https://greenfinancelac.org/resources/news/the-four-benefits-brought-by-using-blockchain-in-capital-markets/"
              target="_blank"
            >
              {" "}
              decision-making
            </Link>
            Cost reduction can be achieved as a result of eliminating
            intermediaries from the process. Enhanced{" "}
            <Link
              color="blue"
              textDecor="underline"
              href="https://greenfinancelac.org/resources/news/the-four-benefits-brought-by-using-blockchain-in-capital-markets/"
              target="_blank"
            >
              {" "}
              liquidity
            </Link>
            is facilitated by tokenizing assets on a blockchain, allowing for
            their division into smaller units, making them more liquid and
            accessible to a broader investor base. Lastly, improved monitoring
            is enabled as blockchain technology{" "}
            <Link
              color="blue"
              textDecor="underline"
              href="https://greenfinancelac.org/resources/news/the-four-benefits-brought-by-using-blockchain-in-capital-markets/"
              target="_blank"
            >
              {" "}
              enhances
            </Link>
            the reporting of the use of proceeds and transparency in impact
            notification.
          </Text>
          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            In practice, firms like Northern Trust have{" "}
            <Link
              color="blue"
              textDecor="underline"
              href="https://www.privateequityinternational.com/building-out-blockchain-in-private-markets/"
              target="_blank"
            >
              {" "}
              incorporated
            </Link>
            blockchain in fund administration, showing the technology's
            practicality beyond theoretical applications​​.
          </Text>

          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Blockchain and AI have found specific applications in the private
            markets sector, offering transformative benefits.
          </Text>
        </Box>

        <Box paddingY="60px">
          <Image src={BlogDetailsImage2} />
        </Box>
        <Box>
          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            One key application is in Credit Servicing, where AI and blockchain
            work in tandem to revolutionize the credit management process. These
            technologies enable more accurate risk assessments and automate
            various aspects of credit servicing, enhancing efficiency and
            reducing risks.
          </Text>
          <Text
            paddingTop="60px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Another significant area is Fund Administration, where blockchain
            implementation, as{" "}
            <Link
              color="blue"
              textDecor="underline"
              href="https://www.privateequityinternational.com/building-out-blockchain-in-private-markets/"
              target="_blank"
            >
              {" "}
              demonstrated
            </Link>
            by Northern Trust, brings transparency and efficiency to fund
            management. Blockchain ensures a transparent record of fund
            activities, reducing administrative complexities.
          </Text>
          <Text
            paddingTop="60px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Furthermore, in Investor Management and Customized Asset Management,
            AI's predictive analytics plays a vital role. It aids in crafting
            personalized investment strategies and improving investor relations.
            This application enhances the overall management of assets and
            investor engagement.
          </Text>
          <Text
            paddingTop="60px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            These specific applications showcase how the synergy of blockchain
            and AI is reshaping the private markets sector, offering
            transparency, efficiency, and personalized solutions to investors
            and institutions alike.
          </Text>
          <Text
            paddingTop="60px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Despite the potential, the adoption of these technologies in private
            markets is not without challenges. Regulatory considerations and the
            need for industry-wide standards are significant hurdles. However,
            as these technologies continue to mature, we can anticipate more
            widespread implementation, opening up private markets to a diverse
            array of investors and revolutionizing the way investments are
            managed and operated.
          </Text>
          <Text
            paddingTop="60px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            The integration of blockchain and AI in private markets is not just
            a futuristic concept but a current reality that's reshaping the
            investment landscape. These technologies offer the promise of a more
            inclusive, efficient, and transparent financial world, breaking down
            the barriers that have long kept private markets exclusive. As we
            embrace these advancements, we stand on the brink of a new era in
            investment, one where technology empowers more people to participate
            in private markets.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default BlogDetailsSub1;
