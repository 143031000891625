import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/header";
import Banner from "./components/banner";
import DocumentAnalyzerSub from "./components/documentAnalyzersub";
import DirectDocument from "./components/directDocument";
import Footer from "../../components/footer";

const DocumentAnalyzer = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <DocumentAnalyzerSub />
      <DirectDocument />
      <Footer />
    </Box>
  );
};

export default DocumentAnalyzer;
