import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

import FinanceImage2 from "../../../../assets/images/akruAdmin/Group 3876.png";
import FinanceImage3 from "../../../../assets/images/akruAdmin/Group 3900.png";
import FinanceImage4 from "../../../../assets/images/akruAdmin/Group 3895.png";
import ProductSheet from "../productSheet";
import Fade from "react-reveal/Fade";
const FinanceHandling = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        {/* section-2 */}
        <Flex
          paddingY={{ base: "0px", xl: "120px" }}
          flexDirection={{ base: "column-reverse", xl: "row" }}
          paddingBottom={{ base: "60px", xl: "120px" }}
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Box
            position="relative"
            paddingTop={{ base: "50px", xl: "0px" }}
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={FinanceImage2}
            />
          </Box>
          <Fade right>
            <Box paddingTop={{ base: "60px", xl: "0px" }}>
              <Text
                width={{ base: "fit-content", xl: "411px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Streamlined Tax Handling
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Effortlessly manage K-1 and 1099 forms with automated upload
                features, ensuring accuracy in tax documentation.
              </Text>
            </Box>
          </Fade>
        </Flex>
        {/* section-3 */}
        <Flex
          paddingBottom={{ base: "60px", xl: "100px" }}
          justifyContent="space-between"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Fade left>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "554px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Automated Compliance Management
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Ensure regulatory adherence with automated calculations and
                applications of withholdings and fees on distributions.
              </Text>
            </Box>
          </Fade>
          <Box
            position="relative"
            paddingY={{ base: "50px", xl: "0px" }}
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={FinanceImage3}
            />
          </Box>
        </Flex>
      </Box>
      <Box bg="#F9F9F9">
        <Box className={isXLScreen ? "container" : "container-responsive"}>
          {/* section-4 */}
          <Flex
            paddingY={{ base: "60px", xl: "100px" }}
            flexDirection={{ base: "column-reverse", xl: "row" }}
            paddingBottom={{ base: "60px", xl: "120px" }}
            justifyContent="space-evenly"
            alignItems={{ base: "start", xl: "center" }}
          >
            <Box
              position="relative"
              paddingTop={{ base: "50px", xl: "0px" }}
              height={{ base: "inherit", xl: "fit-content" }}
              overflow="unset"
            >
              <Image
                height={{ base: "fit-content", xl: "400px" }}
                src={FinanceImage4}
              />
            </Box>
            <Fade right>
              <Box>
                <Text
                  width={{ base: "fit-content", xl: "514px" }}
                  color="#000"
                  fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                  fontStyle="normal"
                  fontWeight={{ base: "600", xl: "500" }}
                  lineHeight={{ base: "40px", xl: " 65px" }}
                >
                  Complex Investment Structure Management
                </Text>
                <Text
                  paddingTop="30px"
                  width={{ base: "fit-content", xl: "554px" }}
                  color="#666"
                  fontSize={{ base: "16px", xl: "24px" }}
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight={{ base: "30px", xl: " 40px" }}
                >
                  Expertly handle diverse investment structures, from parallel
                  funds to sidecars, for sophisticated portfolio management.
                </Text>
              </Box>
            </Fade>
          </Flex>
        </Box>
      </Box>
      <ProductSheet />
    </Box>
  );
};

export default FinanceHandling;
