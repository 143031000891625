import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/header";
import Banner from "./components/banner";
// import Clients from "../Home/components/clients";
import Footer from "../../components/footer";
const ContactUs = () => {
  return (
    <Box>
      <Header />
      <Banner />
      {/* <Clients /> */}
      <Footer />
    </Box>
  );
};

export default ContactUs;
