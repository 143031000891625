import { Box, Flex, Text } from "@chakra-ui/react";
import React, { useState } from "react";

const Process = () => {
  const [hoveredCard, setHoveredCard] = useState(2);

  const handleCardHover = (cardNumber) => {
    setHoveredCard(cardNumber);
  };

  const containerClassName = `background-image-process background-image-${
    hoveredCard || 2
  }`;

  return (
    <Box
      className={containerClassName}
      sx={{
        overflowX: "scroll",
        "::-webkit-scrollbar": {
          display: "none",
        },
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <Flex>
        {/* card-1 */}
        <Flex
          flex="1"
          height="630px"
          backgroundColor={"rgba(253, 133, 52, 0.6)"}
          alignItems="center"
          // onClick={() => handleCardHover(1)}
        >
          <Box
            display={{ base: "none", xl: "block" }}
            width="inherit"
            paddingLeft="20px"
            paddingRight={{ base: "20px", xl: "20px" }}
            transition="opacity 0.3s ease-in-out"
          >
            <Text
              color="#FFF"
              fontSize={{ base: "30px", xl: "48px" }}
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
            >
              Swift and Easy Onboarding
            </Text>
          </Box>
        </Flex>
        {/* card-2 */}
        <Flex
          flex="1"
          minWidth={{ base: "375px", xl:"481px" }}
          height="inherit"
          alignItems="end"
          backgroundColor={
            hoveredCard === 2 ? "rgba(253, 133, 52, 0.6)" : "inherit"
          }
          border="1px solid #fff"
          onClick={() => handleCardHover(2)}
        >
          <Box
            width="inherit"
            paddingX="30px"
            opacity={hoveredCard === 2 ? 2 : 0.7}
            transition="opacity 0.3s ease-in-out"
          >
            <Text
              color={hoveredCard === 2 ? "#FFF" : "#FFF"}
              fontSize="20px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="28px"
            >
              01
            </Text>
            <Text
              paddingTop="20px"
              paddingBottom={hoveredCard === 2 ? "24px" : "50px"}
              color={hoveredCard === 2 ? "#FFF" : "#FFF"}
              fontSize="20px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="28px"
            >
              Seamless Compliance Integration
            </Text>
            {hoveredCard === 2 && (
              <Text
                paddingBottom="30px"
                color="#FFF"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="28px"
              >
                Navigate compliance effortlessly with our streamlined onboarding
                process, ensuring adherence to regulatory standards while
                maintaining a smooth integration experience.
              </Text>
            )}
          </Box>
        </Flex>
        {/* card-3 */}
        <Flex
          minWidth={{ base: "375px", xl:"481px" }}
          flex="1"
          height="inherit"
          alignItems="end"
          backgroundColor={
            hoveredCard === 3 ? "rgba(253, 133, 52, 0.6)" : "inherit"
          }
          border="1px solid #fff"
          onClick={() => handleCardHover(3)}
        >
          <Box
            width="inherit"
            paddingX="30px"
            opacity={hoveredCard === 3 ? 3 : 0.7}
            transition="opacity 0.3s ease-in-out"
          >
            <Text
              color={hoveredCard === 3 ? "#FFF" : "#FFF"}
              fontSize="20px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="28px"
            >
              02
            </Text>
            <Text
              paddingTop="20px"
              paddingBottom={hoveredCard === 3 ? "24px" : "50px"}
              color={hoveredCard === 3 ? "#FFF" : "#FFF"}
              fontSize="20px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="28px"
            >
              Tailor-Made Access Paths
            </Text>
            {hoveredCard === 3 && (
              <Text
                paddingBottom="30px"
                color="#FFF"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="28px"
              >
                Experience customized access pathways that align with your
                preferences and requirements, allowing for a tailored onboarding
                journey that suits your strategic objectives.
              </Text>
            )}
          </Box>
        </Flex>
        {/* card-4 */}
        <Flex
          flex="1"
          minWidth={{ base: "375px", xl:"481px" }}
          height="inherit"
          alignItems="end"
          backgroundColor={
            hoveredCard === 4 ? "rgba(253, 133, 52, 0.6)" : "inherit"
          }
          onClick={() => handleCardHover(4)}
        >
          <Box
            width="inherit"
            paddingX="30px"
            opacity={hoveredCard === 4 ? 4 : 0.7}
            transition="opacity 0.3s ease-in-out"
          >
            <Text
              color={hoveredCard === 4 ? "#FFF" : "#FFF"}
              fontSize="20px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="28px"
            >
              03
            </Text>
            <Text
              paddingTop="20px"
              paddingBottom={hoveredCard === 4 ? "24px" : "50px"}
              color={hoveredCard === 4 ? "#FFF" : "#FFF"}
              fontSize="20px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="28px"
            >
              Swift Engagement
            </Text>
            {hoveredCard === 4 && (
              <Text
                paddingBottom="30px"
                color="#FFF"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="400"
                lineHeight="28px"
              >
                Swiftly acquaint yourself with an exclusive array of offerings,
                expediting your initiation into the realm of private markets.
              </Text>
            )}
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default Process;
