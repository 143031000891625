import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/header";
import Banner from "./components/banner";
import CrossChain from "./components/crossChain";
import CostEfficentAssest from "./components/costEfficentAsset";
import UnmatchedTransparency from "./components/unmatchedTransparency";
import Footer from "../../components/footer";

const AkruAlts = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <CostEfficentAssest />
      <CrossChain />
      <UnmatchedTransparency />
      <Footer />
    </Box>
  );
};

export default AkruAlts;
