import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import Payment1 from "../../../../assets/images/home/Component 2.png";
import Payment2 from "../../../../assets/images/home/Group 3851.png";
import Fade from "react-reveal/Fade";
const RecivePayments = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box backgroundColor=" #F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        {/* section-1 */}
        <Flex
          paddingTop={{ base: "100px", xl: "190px" }}
          justifyContent="space-between"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Fade left>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "524px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", md: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                The Future is Tokenized{" "}
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "515px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Asset tokenization brings a new era of efficiency, democratizing
                investments and reshaping the private market landscape.
              </Text>
              <Box paddingTop="30px">
                <Link href="/learn-more-home">
                  <Button
                    borderRadius="5px"
                    fontSize={{ base: "14px", xl: "16px" }}
                    color="white"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                    paddingX="26px"
                    paddingY="13px"
                    backgroundColor="#FD8534"
                    _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
                    colorScheme="white"
                  >
                    Learn More
                  </Button>
                </Link>
              </Box>
            </Box>
          </Fade>
          <Fade right>
            <Box
              paddingTop={{ base: "50px", xl: "0px" }}
              position="relative"
              width={{ base: "fit-content", xl: "412px" }}
              height={{ base: "inherit", xl: "fit-content" }}
              overflow="unset"
            >
              <Image src={Payment1} />
            </Box>
          </Fade>
        </Flex>
        {/* section-2 */}
        <Flex
          flexDirection={{ base: "column-reverse", xl: "row" }}
          paddingTop={{ base: "60px", xl: "120px" }}
          paddingBottom={{ base: "80px", xl: "100px" }}
          justifyContent="space-between"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Fade left>
            <Box
              paddingTop={{ base: "50px", xl: "0px" }}
              position="relative"
              width={{ base: "fit-content", xl: "412px" }}
              height={{ base: "inherit", xl: "fit-content" }}
              overflow="unset"
              // onMouseEnter={() => setIsHovered2(true)}
              // onMouseLeave={() => setIsHovered2(false)}
            >
              <Image
                src={Payment2}
                // transition="transform 0.3s ease-in-out"
                // opacity={isHovered2 ? 0.7 : 1}
                // transform={`scale(${isHovered2 ? 1.1 : 1})`}
              />
              {/* {isHovered2 && (
              <Box
                width="100%"
                height="100%"
                pos="absolute"
                top="50%"
                left="50%"
                transform="translate(-50%,-50%)"
              ></Box>
            )} */}
            </Box>
          </Fade>
          <Fade right>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "524px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", md: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Unlock Market Dynamics with NLP and AI
              </Text>
              <Text
                paddingTop="20px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Explore a revolutionary approach to private markets, where NLP
                and AI synergize to streamline operations and automate
                processes. This transformative fusion enhances decision-making,
                ensuring a dynamic user experience.
              </Text>
              <Box paddingTop="20px">
                <Link href="/learn-more-home">
                  <Button
                    borderRadius="5px"
                    fontSize={{ base: "14px", xl: "16px" }}
                    color="white"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                    paddingX="26px"
                    paddingY="13px"
                    backgroundColor="#FD8534"
                    _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
                    colorScheme="white"
                  >
                    Learn More
                  </Button>
                </Link>
              </Box>
            </Box>
          </Fade>
        </Flex>
      </Box>
    </Box>
  );
};

export default RecivePayments;
