import React from "react";
import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import TalkToUsUtil from "../../../../../../components/talkToUsSub";

const TalkToUsSub = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box paddingY={{ base: "60px", xl: "120px" }} backgroundColor="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          alignItems="start"
          justifyContent="space-between"
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Box width={{ base: "100%", xl: "55%" }}>
            <Text
              color="#666"
              fontSize="24px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="40px"
            >
              Learn the power of transforming complex financial documents into
              actionable data with our AI-NLP-LLM Document Analyzer. This
              advanced tool goes beyond traditional analysis by providing deep,
              actionable insights that drive strategic decision-making. Connect
              with our team of experts to understand the impact of our unique
              features on your financial operations. Let us demonstrate the ways
              in which the AKRU Document Analyzer can revolutionize your
              approach to data handling, saving you time and enhancing the
              precision of your investment strategies.
            </Text>
          </Box>
          <Box
            marginTop={{ base: "40px", xl: "0px" }}
            backgroundColor="#FFF3EB"
            borderRadius="10px"
            paddingX="30px"
            paddingY="40px"
            width={{ base: "100%", xl: "40%" }}
          >
            <TalkToUsUtil formName="Document Analyzer Talk to Us" />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
export default TalkToUsSub;
