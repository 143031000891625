import React from "react";
import {
  Box,
  Flex,
  Text,
  //   Text,
  useBreakpointValue,
} from "@chakra-ui/react";

import TalkToUsUtil from "../../../../../../components/talkToUsSub";
const TalkToUsSub = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box paddingY={{ base: "60px", xl: "120px" }} backgroundColor="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          alignItems="start"
          justifyContent="space-between"
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Box width={{ base: "100%", xl: "55%" }}>
            <Text
              color="#666"
              fontSize="24px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="40px"
            >
              Step into the future of fund administration with AKRU Admin. Our
              platform brings a new era of efficiency, transparency, and
              strategic insight, powered by natural language processing and
              blockchain technology. 
              <Text paddingY="30px">
                Engage with our experts to understand how AKRU Admin's AI-driven
                document analysis, automated compliance management, and robust
                fundraising support can elevate your fund administration
                processes. 
              </Text>
              We'll focus on your specific needs, demonstrating how our
              innovative solutions address your unique challenges in fund
              administration. Connect with us to redefine financial excellence
              in your organization.
            </Text>
          </Box>
          <Box
            marginTop={{ base: "40px", xl: "0px" }}
            backgroundColor="#FFF3EB"
            borderRadius="10px"
            paddingX="30px"
            paddingY="40px"
            width={{ base: "100%", xl: "40%" }}
          >
            <TalkToUsUtil formName="Akru Admin Talk to Us"  />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
export default TalkToUsSub;
