import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  Input,
  Select,
  Text,
} from "@chakra-ui/react";
import ShowToast from "../showToast";

const TalkToUsUtil = ({formName}) => {
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    Mobilephone: "",
    company: "",
    company_type: "",
    lead_source: formName
  });

  const [formErrors, setFormErrors] = useState({});
  const requiredFields = [
    "firstname",
    "lastname",
    "email",
    "Mobilephone",
    "company",
    "company_type",
  ];
  const validateForm = () => {
    const errors = {};

    requiredFields.forEach((fieldName) => {
      const formattedFieldName = fieldName.replace("_", " ");

      if (!formData[fieldName] || !formData[fieldName].trim()) {
        errors[fieldName] = `${
          formattedFieldName.charAt(0).toUpperCase() +
          formattedFieldName.slice(1)
        } is required`;
      }
    });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      errors.email = "Enter a valid email address";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormChanged = (name, value) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    console.log("trying");
    if (isValid) {
      try {
        const formattedData = {
          fields: Object.keys(formData).map((fieldName) => ({
            objectTypeId: fieldName === "company_type" ? "0-2" : "0-1",
            name: fieldName,
            value: formData[fieldName],
          })),
        };

        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          Mobilephone: "",
          company: "",
          company_type: "",
          lead_source: formName
        });

        const response = await axios.post(
          "https://api.hsforms.com/submissions/v3/integration/submit/20164312/8a10b1b4-38ad-4b87-b1d4-a126d58262eb",
          JSON.stringify(formattedData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response) {
          setFormData({
            firstname: "",
            lastname: "",
            email: "",
            Mobilephone: "",
            company: "",
            company_type: "",
          });

          console.log(response);
          if (response) {
            ShowToast("success", "Thank you for reaching out to us! Our team will be in contact with you shortly.");
          }
        }
      } catch (error) {
        console.error("error", error);
        ShowToast("error", error);
      }
    } else {
      ShowToast("error", "Please fill in all required fields.");
    }
  };
  return (
    <Box>
      <Text
        paddingBottom={{ base: "20px", xl: "40px" }}
        color="#000"
        fontSize="48px"
        fontStyle="normal"
        fontWeight="500"
        lineHeight="normal"
      >
        Talk To Us
      </Text>
      <HStack
        paddingTop="40px"
        gap="40px"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <FormControl
          isRequired={true}
          isInvalid={formErrors.firstname}
          width="100%"
        >
          <Input
            type="text"
            onChange={(e) => handleFormChanged("firstname", e.target.value)}
            value={formData.firstname}
            color="#666"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            height="52px"
            backgroundColor="white"
            border="1px solid #ccc"
            placeholder="First Name"
          />
        </FormControl>
        <FormControl
          isRequired={true}
          isInvalid={formErrors.lastname}
          width="100%"
        >
          <Input
            value={formData.lastname}
            color="#666"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            height="52px"
            backgroundColor="white"
            border="1px solid #ccc"
            onChange={(e) => handleFormChanged("lastname", e.target.value)}
            type="text"
            placeholder="Last Name"
          />
        </FormControl>
        <FormControl
          isRequired={true}
          isInvalid={formErrors.email}
          width="100%"
        >
          <Input
            onChange={(e) => handleFormChanged("email", e.target.value)}
            type="email"
            value={formData.email}
            color="#666"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            height="52px"
            backgroundColor="white"
            border="1px solid #ccc"
            placeholder="Email"
          />
        </FormControl>
        <FormControl
          isRequired={true}
          isInvalid={formErrors.Mobilephone}
          width="100%"
        >
          <Input
            value={formData.Mobilephone}
            type="number"
            color="#666"
            onChange={(e) => handleFormChanged("Mobilephone", e.target.value)}
            fontSize="14px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            height="52px"
            backgroundColor="white"
            border="1px solid #ccc"
            placeholder="Phone Number"
          />
        </FormControl>
        <FormControl
          isRequired={true}
          isInvalid={formErrors.company}
          width="100%"
        >
          <Input
            value={formData.company}
            type="text"
            color="#666"
            fontSize="14px"
            fontStyle="normal"
            onChange={(e) => handleFormChanged("company", e.target.value)}
            fontWeight="500"
            lineHeight="normal"
            height="52px"
            backgroundColor="white"
            border="1px solid #ccc"
            placeholder="Company Name"
          />
        </FormControl>
        <FormControl
          isRequired={true}
          isInvalid={formErrors.company_type}
          width="100%"
        >
          <Select
            color="#666"
            fontSize="14px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
            height="52px"
            backgroundColor="white"
            border="1px solid #ccc"
            value={formData.company_type}
            placeholder="Company Type"
            onChange={(e) => handleFormChanged("company_type", e.target.value)}
          >
            <option>GP / Sponsor</option>
            <option>LP / Investor</option>
            <option>other</option>
          </Select>

        </FormControl>
      </HStack>

      <Flex justifyContent="end" paddingTop="40px">
        <Button
          width="inherit"
          borderRadius="5px"
          fontSize="16px"
          color="white"
          fontStyle="normal"
          fontWeight="500"
          lineHeight="normal"
          paddingX="26px"
          paddingY="13px"
          backgroundColor="#FD8534"
          _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
          colorScheme="white"
          onClick={handleSubmitForm}
        >
          Submit
        </Button>
      </Flex>
    </Box>
  );
};

export default TalkToUsUtil;
