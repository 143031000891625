import {
  Box,
  Divider,
  Flex,
  HStack,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import InsightCard1 from "../../../../assets/images/blog/Rectangle 5832.png";
import InsightCard2 from "../../../../assets/images/blog/Rectangle 5836.png";
import InsightCard3 from "../../../../assets/images/blog/Rectangle 5839.png";

const BlogCards = () => {
  const history = useNavigate();
  const [activeCard, setActiveCard] = useState(null);

  const isXLScreen = useBreakpointValue({ base: false, xl: true });

  const handleClick = (cardNumber) => {
    setActiveCard(cardNumber === activeCard ? null : cardNumber);
  };
  const cardData = [
    {
      imageSrc: InsightCard1,
      title: "How Smart Contracts are ...",
      description:
        "The financial landscape, particularly in private markets, is witnessing a transformative shift with the integration of smart contracts.",
    },
    {
      imageSrc: InsightCard2,
      title: "The Role of Blockchain in...",
      description:
        "In the complex and often opaque realm of private markets, transparency remains a perennial challenge. ",
    },
    {
      imageSrc: InsightCard3,
      title: "Beyond Traditional Investing...",
      description:
        "Till now, private markets have remained relatively inaccessible to a broader audience due to their complex nature and high entry .",
    },
  ];
  const handleBlogClicked = (ind) => {
    switch (ind) {
      case 0:
        history("/blog-details-sub2");
        window.scroll(0,0)
        break;
      case 1:
        history("/blog-details-sub3");
        window.scroll(0,0)
        break;
      case 2:
        history("/blog-details-sub4");
        window.scroll(0,0)
        break;

      default:
        break;
    }
  };

  return (
    <Box backgroundColor="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <HStack
          flexWrap="wrap"
          paddingTop="100px"
          justifyContent={{ base: "center", xl: "space-between" }}
          gap="20px"
          sx={{
            overflowX: "scroll",
            "::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          {cardData.map((card, index) => (
            <Box
              onClick={() => handleBlogClicked(index)}
              key={index}
              onMouseEnter={() => handleClick(index)}
              onMouseLeave={() => handleClick(null)}
              cursor="pointer"
              backgroundColor="White"
              border="1px solid #ccc"
              borderRadius="8px"
              width={{ base: "fill-available", xl: "350px" }}
            >
              <Image width="fill-available" src={card.imageSrc} />
              <Box paddingTop="20px" paddingX="20px">
                <Text
                  color="#000"
                  fontSize="20px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="28px"
                >
                  {card.title}
                </Text>
                <Text
                  color="#666"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="400"
                  lineHeight="28px"
                  paddingTop="10px"
                >
                  {card.description}
                </Text>
              </Box>

              <Divider paddingTop="30px" />
              <Flex
                paddingY="17px"
                paddingX="24px"
                justifyContent="space-between"
                alignItems="center"
                style={{ cursor: "pointer" }}
              >
                <Text
                  cursor="pointer"
                  color={activeCard === index ? "#FD8534" : "#000"}
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                >
                  Read More
                </Text>
                <Box>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="11"
                    viewBox="0 0 22 11"
                    fill={activeCard === index ? "#FD8534" : "black"}
                  >
                    <path
                      d="M0.71035 4.33374H18.8953L15.8461 1.281C15.7297 1.14503 15.6688 0.970126 15.6757 0.791243C15.6826 0.612361 15.7568 0.442673 15.8834 0.31609C16.01 0.189506 16.1797 0.11535 16.3585 0.10844C16.5374 0.101531 16.7123 0.162377 16.8483 0.278819L21.1129 4.54342C21.1965 4.62549 21.2591 4.72642 21.2956 4.83773C21.3321 4.94904 21.3413 5.06748 21.3226 5.18311C21.2905 5.31695 21.2229 5.43969 21.1271 5.53849L16.8625 9.80309C16.7988 9.87749 16.7204 9.93792 16.6322 9.98058C16.544 10.0232 16.448 10.0472 16.3501 10.051C16.2522 10.0548 16.1546 10.0383 16.0634 10.0026C15.9722 9.96682 15.8893 9.91262 15.8201 9.84335C15.7508 9.77408 15.6966 9.69125 15.6609 9.60004C15.6251 9.50883 15.6086 9.41122 15.6124 9.31333C15.6162 9.21544 15.6402 9.1194 15.6828 9.03121C15.7255 8.94303 15.7859 8.86462 15.8603 8.80091L18.8953 5.75527H0.71035C0.521843 5.75527 0.341057 5.68039 0.207762 5.54709C0.0744667 5.4138 -0.000415802 5.23301 -0.000415802 5.04451C-0.000415802 4.856 0.0744667 4.67521 0.207762 4.54192C0.341057 4.40862 0.521843 4.33374 0.71035 4.33374Z"
                      fill={activeCard === index ? "#FD8534" : "black"}
                    />
                  </svg>
                </Box>
              </Flex>
            </Box>
          ))}
        </HStack>
      </Box>
      <Flex width="100%" justifyContent="center" paddingY="100px">
        <Flex
          minWidth="22%"
          alignItems="center"
          justifyContent="space-between"
          padding="20px"
          borderRadius=" 5px"
          border="1px solid #EEE"
          background=" #FFF"
        >
          <Box>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="10"
              viewBox="0 0 22 10"
              fill="none"
            >
              <path
                d="M20.6217 4.22583H2.43673L5.48592 1.17309C5.60236 1.03712 5.66321 0.862216 5.6563 0.683333C5.64939 0.504451 5.57523 0.334763 5.44865 0.208179C5.32206 0.0815958 5.15238 0.00743954 4.97349 0.000530074C4.79461 -0.00637939 4.61971 0.0544668 4.48374 0.170909L0.219142 4.43551C0.135565 4.51758 0.0729029 4.61851 0.0364178 4.72982C-6.74079e-05 4.84113 -0.00930997 4.95957 0.00946636 5.0752C0.0415802 5.20904 0.10909 5.33178 0.204927 5.43058L4.46952 9.69518C4.53324 9.76958 4.61165 9.83001 4.69983 9.87267C4.78801 9.91533 4.88406 9.93931 4.98195 9.94309C5.07983 9.94687 5.17744 9.93038 5.26865 9.89464C5.35986 9.85891 5.4427 9.80471 5.51197 9.73544C5.58124 9.66617 5.63544 9.58333 5.67117 9.49213C5.7069 9.40092 5.7234 9.30331 5.71962 9.20542C5.71584 9.10753 5.69186 9.01149 5.6492 8.9233C5.60654 8.83512 5.54611 8.75671 5.4717 8.693L2.43673 5.64736H20.6217C20.8102 5.64736 20.991 5.57248 21.1243 5.43918C21.2576 5.30589 21.3324 5.1251 21.3324 4.9366C21.3324 4.74809 21.2576 4.5673 21.1243 4.43401C20.991 4.30071 20.8102 4.22583 20.6217 4.22583Z"
                fill="#666666"
              />
            </svg>
          </Box>
          <Text
            color="#666"
            fontSize="16px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
          >
            1 . 2 . 3
          </Text>
          <Box>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="10"
              viewBox="0 0 22 10"
              fill="none"
            >
              <path
                d="M0.71035 4.22583H18.8953L15.8461 1.17309C15.7297 1.03712 15.6688 0.862216 15.6757 0.683333C15.6826 0.50445 15.7568 0.334763 15.8834 0.208179C16.01 0.0815958 16.1797 0.00743954 16.3585 0.000530074C16.5374 -0.00637939 16.7123 0.0544668 16.8483 0.170909L21.1129 4.43551C21.1965 4.51758 21.2591 4.61851 21.2956 4.72982C21.3321 4.84113 21.3413 4.95957 21.3226 5.0752C21.2905 5.20904 21.2229 5.33178 21.1271 5.43058L16.8625 9.69518C16.7988 9.76958 16.7204 9.83001 16.6322 9.87267C16.544 9.91533 16.448 9.93931 16.3501 9.94309C16.2522 9.94687 16.1546 9.93038 16.0634 9.89464C15.9722 9.85891 15.8893 9.80471 15.8201 9.73544C15.7508 9.66617 15.6966 9.58333 15.6609 9.49213C15.6251 9.40092 15.6086 9.30331 15.6124 9.20542C15.6162 9.10753 15.6402 9.01149 15.6828 8.9233C15.7255 8.83512 15.7859 8.75671 15.8603 8.69299L18.8953 5.64736H0.71035C0.521843 5.64736 0.341057 5.57248 0.207762 5.43918C0.0744675 5.30589 -0.000415977 5.1251 -0.000415977 4.9366C-0.000415977 4.74809 0.0744675 4.5673 0.207762 4.43401C0.341057 4.30071 0.521843 4.22583 0.71035 4.22583Z"
                fill="#666666"
              />
            </svg>
          </Box>
        </Flex>
      </Flex>
    </Box>
  );
};

export default BlogCards;
