import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/header";
import Banner from "./components/banner";
import BlogDetailsSub1 from "./components/blogDetailsSub1";
import BlogDetailsSub2 from "./components/blogDetailsSub2";
import Footer from "../../components/footer";

const BlogDetails4 = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <BlogDetailsSub1 />
      <BlogDetailsSub2 />
      <Footer />
    </Box>
  );
};

export default BlogDetails4;
