import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useState } from "react";
import BookImage from "../../../../assets/images/home/image 6.png";
import Fade from "react-reveal/Fade";
const StartBusiness = () => {
  const [isHovered, setIsHovered] = useState(false);
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box backgroundColor="#F9F9F9" paddingY="80px">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          justifyContent="space-between"
          alignItems={{ base: "center", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Fade left>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "486px" }}
                color="#000"
                fontSize={{ base: "27px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: "65px" }}
              >
                Eclipsing Traditional Boundaries in Private Markets
              </Text>
              <Text
                paddingTop={{ base: "20px", xl: "30px" }}
                width={{ base: "fit-content", xl: "620px" }}
                color="#666"
                fontSize={{ base: "14px", xl: "24px" }}
                fontStyle="normal"
                fontWeight=" 400"
                lineHeight={{ base: "30px", xl: "40px" }}
              >
                AKRU On-Fi is transforming private markets, opening new
                investment opportunities and redefining access and growth in the
                financial landscape.
              </Text>
            </Box>
          </Fade>

          <Box
            paddingTop={{ base: "50px", xl: "0px" }}
            position="relative"
            width={{ base: "fit-content", xl: "412px" }}
            height={{ base: "inherit", xl: "482px" }}
            overflow="hidden"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Image
              src={BookImage}
              transition="transform 0.3s ease-in-out"
              // opacity={isHovered ? 0.7 : 1}
              transform={`scale(${isHovered ? 1.1 : 1})`}
            />
            {isHovered && (
              <Box
                width={{ base: "85%", xl: "90%" }}
                height={{ base: "85%", xl: "90%" }}
                border="2px solid #FD8534"
                pos="absolute"
                top={{ base: "55%", sm: "53%", xl: "50%" }}
                left="50%"
                transform="translate(-50%,-50%)"
                borderRadius="5px"
              ></Box>
            )}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default StartBusiness;
