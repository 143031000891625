import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/header";
import Banner from "./components/banner";
import Services from "../Home/components/services";
import Strategy from "./components/strategy";
import Footer from "../../components/footer";
const AboutUs = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <Services />
      <Strategy />
      <Footer />
    </Box>
  );
};

export default AboutUs;
