import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import InvestorImage1 from "../../../../assets/images/AkruInvestorManagment/Group 3879.png";
import InvestorImage2 from "../../../../assets/images/AkruInvestorManagment/Group 3880.png";
import CommunicationImage2 from "../../../../assets/images/AkruInvestorManagment/Group 3881.png";
import AkruImSpecialities from "../akruImSpecialities";
import Fade from "react-reveal/Fade";
const InvestorManagment = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box backgroundColor="#F9F9F9" paddingBottom="120px">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        {/* section-1 */}
        <Flex
          paddingTop="100px"
          justifyContent="space-between"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Fade left>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "466px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Centralized Investor Management
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                An all-in-one portal for efficient investor and contact
                management, enhancing interactions and decision-making.
              </Text>
            </Box>
          </Fade>
          <Box
            paddingTop={{ base: "50px", xl: "0px" }}
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={InvestorImage1}
            />
          </Box>
        </Flex>
        {/* section-2 */}
        <Flex
          flexDirection={{ base: "column-reverse", xl: "row" }}
          paddingTop={{ base: "60px", xl: "120px" }}
          paddingBottom={{ base: "80px", xl: "147px" }}
          justifyContent="space-between"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Box
            position="relative"
            paddingTop={{ base: "50px", xl: "0px" }}
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={InvestorImage2}
            />
          </Box>
          <Fade right>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "411px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Subscription Automation
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Streamline subscriptions with data accuracy, document assembly,
                and seamless e-signature integration.
              </Text>
            </Box>
          </Fade>
        </Flex>
        <Flex
          paddingBottom={{ base: "60px", xl: "100px" }}
          justifyContent="space-between"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Fade left>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "466px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Engagement Tracking
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Monitor tasks and investor activities to boost productivity and
                strategic insights.
              </Text>
            </Box>
          </Fade>
          <Box
            position="relative"
            paddingY={{ base: "50px", xl: "0px" }}
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={CommunicationImage2}
            />
          </Box>
        </Flex>
      </Box>
      <AkruImSpecialities />
    </Box>
  );
};

export default InvestorManagment;
