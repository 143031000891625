import { Box, Divider, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className="bg-aonfiSub">
        <Box
          height="fill-available"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 5.29%, #000 100%);"
        >
          <Box className={isXLScreen ? "container" : "container-responsive"}>
            <Flex height="400px" alignItems="center">
              <Flex justifyContent="center" width="100%">
                <Text
                  width={{ base: "100%", xl: "594px" }}
                  textAlign="center"
                  color=" #FFF"
                  fontSize={{ base: "30px", xl: " 48px" }}
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight={{ base: "50px", xl: "70px" }}
                >
                  Shaping the Financial Connectivity of Tomorrow
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box backgroundColor="#F9F9F9">
        <Box
          className={isXLScreen ? "container" : "container-responsive"}
          paddingTop="80px"
        >
          <Text
            paddingBottom="80px"
            textAlign="start"
            color=" #666"
            fontSize={{ base: "16px", xl: " 24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight={{ base: "30px", xl: "40px" }}
          >
            Step into a world where financial boundaries are redefined by the
            A-On Fi Network. Our product sheet takes you on a journey through
            innovative financial solutions that revolutionize how institutions
            interact and transact. Download the product sheet now to see how the
            A-On Fi Network is not just changing the game in financial
            operations, but also creating new opportunities for global market
            engagement and advanced transactional efficiency.
          </Text>
          <Divider border="1px solid #ccc" />
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
