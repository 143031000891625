import { Box, Divider, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className="bg-dataAnalyzerSub">
        <Box
          height="fill-available"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 5.29%, #000 100%);"
        >
          <Box className={isXLScreen ? "container" : "container-responsive"}>
            <Flex height="400px" alignItems="center">
              <Flex justifyContent="center" width="100%">
                <Text
                  textAlign="center"
                  color=" #FFF"
                  fontSize={{ base: "30px", xl: " 48px" }}
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight={{ base: "50px", xl: "70px" }}
                >
                  Elevating Your Financial Analysis
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box backgroundColor="#F9F9F9">
        <Box
          className={isXLScreen ? "container" : "container-responsive"}
          paddingTop="80px"
        >
          <Text
            paddingBottom="80px"
            textAlign="start"
            color=" #666"
            fontSize={{ base: "16px", xl: " 24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight={{ base: "30px", xl: "40px" }}
          >
            Explore the cutting-edge capabilities of the AKRU Document Analyzer
            through our exclusive product sheet. Delve deeper into how
            AI-NLP-LLM technology can transform your handling of complex
            financial documents. Discover how the Analyzer not only simplifies
            data interpretation but also provides foresight into market trends
            and investment opportunities. Download now to gain a deeper
            understanding of how the AKRU Document Analyzer can be a
            game-changer in your financial analysis and strategic planning.
          </Text>
          <Divider border="1px solid #ccc" />
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
