import {
  Box,
  Flex,
  HStack,
  Text,
  Image,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Fade from "react-reveal/Fade";

const CostEfficentAssest = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  const isSmallScreen = useBreakpointValue({ base: true, xl: false });
  const [selectedOption, setSelectedOption] = useState(
    "Cost-Efficient Asset Digitization"
  );

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const getContainerStyles = (option) => ({
    borderRadius: "5px",
    background: selectedOption === option ? "#FFF" : "white",
    boxShadow:
      selectedOption === option
        ? "0px 4px 10px 0px rgba(0, 0, 0, 0.10)"
        : "none",
    color: selectedOption === option ? "#FD8534" : "#999",
    fontWeight: selectedOption === option ? "500" : "500",
    transition: "all 0.3s ease-in-out",
  });

  const optionDescriptions = {
    "Cost-Efficient Asset Digitization":
      "Seamlessly digitize assets with reduced costs in management, trading, and settlement, benefiting both investors and issuers.",
    "Customized Asset Management":
      "Tailor your investment strategy with personalized asset management solutions, designed to align with your unique financial goals.",
    "On-Chain Identity":
      " Leverage blockchain's robust and secure infrastructure to authenticate and verify identities, ensuring tamper-proof identity management.",
  };

  const optionImages = {
    "Cost-Efficient Asset Digitization": require("../../../../assets/images/akruAlts/Group 3896.png"),
    "Customized Asset Management": require("../../../../assets/images/akruAlts/2.png"),
    "On-Chain Identity": require("../../../../assets/images/akruAlts/1.png"),
  };

  return (
    <Box backgroundColor="#F9F9F9">
      <Box
        paddingBottom={{ base: "30px", xl: "100px" }}
        className={isXLScreen ? "container" : "container-responsive"}
      >
        <Box>
          <Flex
            alignItems={{ base: "start", xl: "center" }}
            flexDirection="column"
            paddingTop="100px"
          >
            <HStack
              width="100%"
              spacing="0"
              flexDirection={{ base: "column", lg: "row" }}
            >
              {Object.keys(optionDescriptions).map((option) => (
                <Text
                  key={option}
                  width={{ base: "100%", lg: "390px" }}
                  borderRadius="5px"
                  cursor="pointer"
                  textAlign="center"
                  fontSize={{ base: "12px", xl: "16px" }}
                  lineHeight={{ base: "28px", xl: "34px" }}
                  paddingY={{ base: "15px", xl: "20px" }}
                  paddingX={{ base: "20px", xl: "35px" }}
                  marginBottom={!isXLScreen && !isSmallScreen && "10px"}
                  onClick={() => handleOptionClick(option)}
                  {...getContainerStyles(option)}
                >
                  {option}
                </Text>
              ))}
            </HStack>
            {selectedOption && (
              <Box
                paddingTop={{ base: "0px", xl: "30px" }}
                marginLeft={!isXLScreen && !isSmallScreen && "20px"}
              >
                <Flex
                  paddingY={{ base: "0px", md: "50px", xl: "0px" }}
                  alignItems={{ base: "center", md: "start" }}
                  justifyContent="space-between"
                  flexDirection={{ base: "column", lg: "row" }}
                  width="fit-content"
                >
                  <Fade left>
                    <Box
                      width={{ base: "100%", lg: "50%", xl: "75%" }}
                      paddingTop={{ base: "30px", xl: "60px" }}
                    >
                      <Text
                        cursor="pointer"
                        color="#000"
                        fontSize={{ base: "28px", xl: "36px" }}
                        fontWeight={{ base: "600", xl: "500" }}
                        lineHeight={{ base: "40px", xl: "50px" }}
                        textAlign="start"
                      >
                        {selectedOption}
                      </Text>
                      <Text
                        paddingTop={{ base: "20px", xl: "30px" }}
                        color="#666"
                        fontWeight="400"
                        fontSize={{ base: "16px", xl: "20px" }}
                        lineHeight={{ base: "30px", xl: "30px" }}
                        textAlign="start"
                      >
                        {optionDescriptions[selectedOption]}
                      </Text>
                    </Box>
                  </Fade>

                  <Flex
                    paddingY={{ base: "30px", xl: "0px" }}
                    w={{ base: "fill-available", xl: "100%" }}
                    justifyContent="center"
                  >
                    <Fade right>
                      <Image
                        h="150px"
                        src={optionImages[selectedOption]}
                        alt={`Image for ${selectedOption}`}
                        objectFit="cover"
                        borderRadius="5px"
                        marginTop={{ base: "10px", xl: "80px" }}
                      />
                    </Fade>
                  </Flex>
                </Flex>
              </Box>
            )}
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default CostEfficentAssest;
