import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

const FinanceFirm = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Flex pos="relative">
      <Box
        className={isXLScreen ? "container" : "container-responsive"}
        paddingY="55px"
        marginBottom={{ base: "300px", xl: "0px" }}
      >
        <Text
          width={{ base: "fit-content", xl: "681px" }}
          color="#000000"
          fontSize={{ base: "27px", md: "36px", xl: "48px" }}
          fontStyle="normal"
          fontWeight={{ base: "600", md: "500" }}
          lineHeight={{ base: "40px", xl: " 65px" }}
        >
          Embrace the Future of Private Investing with AKRU
        </Text>
        <Text
          paddingTop="5px"
          width={{ base: "fit-content", xl: "696px" }}
          color=" #666"
          fontSize={{ base: "14px", md: "16px", xl: "20px" }}
          fontStyle="normal"
          fontWeight="400"
          lineHeight={{ base: "30px", xl: " 40px" }}
        >
          Ready to experience the future of private markets with AKRU? Get
          started now and unlock a world of cutting-edge financial
          opportunities.
        </Text>
      </Box>
      <Box
        className="finance-firm-bg"
        width={{ base: "80%", xl: "36%" }}
        height={{ base: "", xl: "325px" }}
        pos="absolute"
      >
        <Flex height="inherit" alignItems="center" justifyContent="center">
          <Link href="/learn-more-home">
            <Button
              borderRadius="5px"
              fontSize={{ base: "14px", md: "16px", xl: "18px" }}
              color="#FD8534"
              _hover={{ color: "white", backgroundColor: "#FD9D5D" }}
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
              paddingX="26px"
              paddingY="13px"
              backgroundColor="white"
              colorScheme="white"
            >
              Learn More
            </Button>
          </Link>
        </Flex>
      </Box>
    </Flex>
  );
};

export default FinanceFirm;
