import { Box, Divider, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React, { useState } from "react";
const PaymentMethods = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  const [selectedOption, setSelectedOption] = useState(
    "AKRU Alts. Custom Private Markets"
  );

  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  const getTextColor = (option) => {
    return selectedOption === option ? "#FD8534" : "#999";
  };
  const getFontWeight = (option) => {
    return selectedOption === option ? "500" : "600";
  };
  const optionDescriptions = {
    "AKRU Alts. Custom Private Markets":
      "    AKRU Alts. Custom Private Markets Platform reshapes the way investors access and engage with alternative investments. Tailored specifically for the unique dynamics of private markets, this platform leverages blockchain technology to offer a seamless, secure, and transparent investment experience. Allowing investors to tailor their investment strategies to suit their specific needs. ",
    "AKRU Admin":
      "AKRU Admin is your comprehensive fund administration solution, ensuring transparency in capital accounts, seamless K1/Tax document processing, and efficient waterfall management. Powered by immutable blockchain records and an NLP-driven engine, our platform swiftly extracts essential data from financial documents, empowering informed decision-making and operational efficiency.",
    "AKRU IM":
      "AKRU Investor Management simplifies and streamlines investor management with an all-in-one platform. Efficiently monitor engagement, enhance communication insight, and customize data fields. Visualize deals with a dynamic pipeline tool. Whether you're focused on investor relations or fundraising, AKRU Investor Management empowers your financial success.",
    "AKRU Servicing":
      "AKRU Servicing streamlines transaction processes for ABS, MBS, and Warehouse Lending using smart contracts. These contracts establish and manage deal structures, eliminating data matching and monthly statement computations. Experience efficient and accurate credit management ensuring smoother financial operations.",
    "AKRU Document Analyzer":
      "Our AI-NLP-LLM Document Analyzer is designed to elevate investment oversight and revolutionize document analysis. Through the synergy of natural language processing (NLP) and advanced machine learning (LLM), our Document Analyzer converts complex documents into actionable data, enabling you to focus on strategic decision-making.",
    "A On-Fi Network":
      "AKRU's A-On Fi Network unlocks a new era of financial connectivity through cross-chain interoperability. Breaking down silos, this innovative platform connects major financial institutions, revolutionizing cross-chain transactions. By seamlessly integrating on-chain identity verification and smart contracts, A-On Fi sets a new standard for private market connectivity. ",
  };
  // for responsive
  const optionsForResponsove = [
    "AKRU Alts. Custom Private Markets",
    "AKRU Admin",
    "AKRU IM",
    "AKRU Servicing",
    "AKRU Document Analyzer",
    "A On-Fi Network",
  ];
  const [selectedOptionResponsive, setSelectedOptionResponsive] = useState(
    optionsForResponsove[0]
  );
  const [lastClickedArrow, setLastClickedArrow] = useState(null);
  const handleOptionClickResponsive = (optionsForResponsove) => {
    setSelectedOptionResponsive(optionsForResponsove);
  };
  const handleArrowClickResponsive = (direction) => {
    const currentIndex = optionsForResponsove.indexOf(selectedOption);
    let newIndex;

    if (direction === "left") {
      newIndex =
        (currentIndex - 1 + optionsForResponsove.length) %
        optionsForResponsove.length;
      setLastClickedArrow("left");
    } else {
      newIndex = (currentIndex + 1) % optionsForResponsove.length;
      setLastClickedArrow("right");
    }

    setSelectedOption(optionsForResponsove[newIndex]);
  };
  const getTextColorResponsive = (optionsForResponsove) => {
    return selectedOptionResponsive === optionsForResponsove
      ? "#FD8534"
      : "#999";
  };
  const getFontWeightResponsive = (optionsForResponsove) => {
    return selectedOptionResponsive === optionsForResponsove ? "500" : "600";
  };
  return (
    <Box
      paddingTop="100px"
      paddingBottom="130px"
      className={isXLScreen ? "container" : "container-responsive"}
    >
      <Flex flexDirection="column" alignItems="center">
        <Box>
          <Text
            width={{ base: "fit-content", xl: "685px" }}
            color=" #000"
            textAlign="center"
            fontSize={{ base: "30px", md: "40px", xl: "64px" }}
            fontStyle="normal"
            fontWeight={{ base: "600", xl: "500" }}
            lineHeight="70px"
          >
            Our Products
          </Text>
        </Box>
        <Box width={{ base: "fit-content", xl: "768px" }}>
          <Text
            paddingTop="10px"
            color=" #666"
            textAlign="center"
            fontSize={{ base: "16px", xl: "20px" }}
            fontStyle="normal"
            fontWeight=" 400"
            lineHeight="40px"
          >
            Private Markets, Redefined.
          </Text>
        </Box>
      </Flex>
      <Box paddingTop={{ base: "30px", xl: "100px" }}>
        <Flex alignItems="center" flexDirection={{ base: "column", xl: "row" }}>
          {/* responsive */}
          <Box display={{ base: "hidden", xl: "none" }}>
            <Flex justifyContent="center" alignItems="center">
              <Box
                height="50px"
                maxWidth="fit-content"
                backgroundColor="white"
                boxShadow="4px 4px 15px 0px rgba(0, 0, 0, 0.07)"
                borderRadius="5px"
                display="flex"
                flexDirection="column"
                alignItems="center"
              >
                <Flex
                  height="100%"
                  paddingX="13px"
                  justifyContent="space-between"
                  minWidth={{ base: "300px", md: "380px" }}
                  alignItems="center"
                >
                  <svg
                    onClick={() => handleArrowClickResponsive("left")}
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-arrow-narrow-left"
                    width="36"
                    height="36"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke={lastClickedArrow === "left" ? "#FD8534" : "#999"}
                    fill={lastClickedArrow === "left" ? "#FD8534" : "#999"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 12l14 0" />
                    <path d="M5 12l4 4" />
                    <path d="M5 12l4 -4" />
                  </svg>
                  <Text
                    width="fit-content"
                    fontSize="14px"
                    cursor="pointer"
                    color={getTextColorResponsive(selectedOptionResponsive)}
                    textAlign="center"
                    fontStyle="normal"
                    fontWeight={getFontWeightResponsive(
                      selectedOptionResponsive
                    )}
                    onClick={() =>
                      handleOptionClickResponsive(selectedOptionResponsive)
                    }
                  >
                    {selectedOption}
                  </Text>
                  <svg
                    onClick={() => handleArrowClickResponsive("right")}
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-arrow-narrow-right"
                    width="36"
                    height="36"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke={lastClickedArrow === "right" ? "#FD8534" : "#999"}
                    fill={lastClickedArrow === "right" ? "#FD8534" : "#999"}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 12l14 0" />
                    <path d="M15 16l4 -4" />
                    <path d="M15 8l4 4" />
                  </svg>
                </Flex>
              </Box>
            </Flex>
          </Box>
          <Box display={{ base: "none", xl: "hidden" }}>
            <Box
              width="fit-content"
              backgroundColor="white"
              boxShadow="4px 4px 15px 0px rgba(0, 0, 0, 0.07)"
              borderRadius="5px"
            >
              <Text
                width="fit-content"
                cursor="pointer"
                color={getTextColor("AKRU Alts. Custom Private Markets")}
                textAlign="start"
                fontSize="20px"
                fontStyle="normal"
                fontWeight={getFontWeight(selectedOption)}
                lineHeight="34px"
                paddingY="20px"
                paddingX="35px"
                onClick={() =>
                  handleOptionClick("AKRU Alts. Custom Private Markets")
                }
              >
                AKRU Alts. Custom Private Markets
              </Text>
              <Divider />
              <Text
                width="fit-content"
                cursor="pointer"
                color={getTextColor("AKRU Admin")}
                textAlign="start"
                fontSize="20px"
                fontStyle="normal"
                fontWeight={getFontWeight(selectedOption)}
                lineHeight="34px"
                paddingY="20px"
                paddingX="35px"
                onClick={() => handleOptionClick("AKRU Admin")}
              >
                AKRU Admin
              </Text>
              <Divider />
              <Text
                width="fit-content"
                cursor="pointer"
                color={getTextColor("AKRU IM")}
                textAlign="start"
                fontSize="20px"
                fontStyle="normal"
                fontWeight={getFontWeight(selectedOption)}
                lineHeight="34px"
                paddingY="20px"
                paddingX="35px"
                onClick={() => handleOptionClick("AKRU IM")}
              >
                AKRU IM
              </Text>
              <Divider />
              <Text
                width="fit-content"
                cursor="pointer"
                color={getTextColor("AKRU Servicing")}
                textAlign="start"
                fontSize="20px"
                fontStyle="normal"
                fontWeight={getFontWeight(selectedOption)}
                lineHeight="34px"
                paddingY="20px"
                paddingX="35px"
                onClick={() => handleOptionClick("AKRU Servicing")}
              >
                AKRU Servicing
              </Text>
              <Divider />
              <Text
                width="fit-content"
                cursor="pointer"
                color={getTextColor("AKRU Document Analyzer")}
                textAlign="start"
                fontSize="20px"
                fontStyle="normal"
                fontWeight={getFontWeight(selectedOption)}
                lineHeight="34px"
                paddingY="20px"
                paddingX="35px"
                onClick={() => handleOptionClick("AKRU Document Analyzer")}
              >
                AKRU Document Analyzer
              </Text>
              <Divider />
              <Text
                width="fit-content"
                cursor="pointer"
                color={getTextColor("A On-Fi Network")}
                textAlign="start"
                fontSize="20px"
                fontStyle="normal"
                fontWeight={getFontWeight(selectedOption)}
                lineHeight="34px"
                paddingY="20px"
                paddingX="35px"
                onClick={() => handleOptionClick("A On-Fi Network")}
              >
                A On-Fi Network
              </Text>
            </Box>
          </Box>
          {selectedOption && (
            <Box
              paddingLeft={{ base: "0px", xl: "70px" }}
              paddingTop={{ base: "40px", xl: "0px" }}
              width={{ base: "fit-content", xl: "700px" }}
            >
              <Text
                cursor="pointer"
                color=" #000"
                fontSize={{ base: "27px", md: "30px", xl: "36px" }}
                fontStyle="normal"
                fontWeight={{ base: " 600", xl: "500" }}
                lineHeight={{ base: "50px", xl: "65px" }}
              >
                {selectedOption}
              </Text>
              <Text
                paddingTop="10px"
                color="#666"
                fontSize={{ base: "14px", md: "16px", xl: "20px" }}
                fontStyle="normal"
                fontWeight=" 400"
                lineHeight={{ base: "30px", xl: "40px" }}
              >
                {optionDescriptions[selectedOption]}
              </Text>
            </Box>
          )}
        </Flex>
      </Box>
    </Box>
  );
};

export default PaymentMethods;
