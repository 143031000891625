import React, { useState } from "react";
import axios from "axios";
import {
  Box,
  Button,
  Flex,
  FormControl,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import ShowToast from "../../../../../../components/showToast";

const LetsTalk = ({formName}) => {
  
  
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobilephone: "",
    company: "",
    company_type: "",
    assest_class: "",
    lead_source: formName
  });
  const [formErrors, setFormErrors] = useState({});
  const requiredFields = [
    "firstname",
    "lastname",
    "email",
    "mobilephone",
    "company",
    "company_type",
    "assest_class",
  ];
  const validateForm = () => {
    const errors = {};

    requiredFields.forEach((fieldName) => {
      const formattedFieldName = fieldName.replace("_", " ");

      if (!formData[fieldName] || !formData[fieldName].trim()) {
        errors[fieldName] = `${
          formattedFieldName.charAt(0).toUpperCase() +
          formattedFieldName.slice(1)
        } is required`;
      }
    });

    

    if (!formData.assest_class) {
      errors.assest_class = "Asset class is required";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      errors.email = "Enter a valid email address";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormChanged = (name, value) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    console.log("trying");
    if (isValid) {
      try {
        const formattedData = {
          fields: Object.keys(formData).map((fieldName) => ({
            objectTypeId: "0-1",
            name: fieldName,
            value: formData[fieldName],
          })),
        };
        const response = await axios.post(
          "https://api.hsforms.com/submissions/v3/integration/submit/20164312/13516feb-59e2-4606-b6c2-970a547926ba",
          JSON.stringify(formattedData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response) {
          setFormData({
            firstname: "",
            lastname: "",
            email: "",
            mobilephone: "",
            company: "",
            company_type: "",
            assest_class: "",
            lead_source: formName
          });

          console.log(response);
          if (response) {
            ShowToast("success", "Thank you for your interest! Our team will be in contact with you shortly.");
          }
        }
      } catch (error) {
        console.error("error", error);
        ShowToast("error", error);
      }
    } else {
      ShowToast("error", "Please fill in all required fields.");
    }
  };

  return (
    <Box paddingY="80px" backgroundColor="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex justifyContent="center">
          <Text
            color="#000"
            fontSize="48px"
            fontStyle="normal"
            fontWeight="500"
            lineHeight="normal"
          >
            Let's Talk
          </Text>
        </Flex>
        <form>
          <HStack
            paddingTop="40px"
            gap="40px"
            flexWrap="wrap"
            justifyContent="space-between"
          >
            <FormControl
              isRequired={true}
              isInvalid={formErrors.firstname}
              width={{ base: "100%", xl: "48%" }}
            >
              <Input
                type="text"
                onChange={(e) => handleFormChanged("firstname", e.target.value)}
                value={formData.firstname}
                color="#666"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                height="52px"
                backgroundColor="white"
                border="1px solid #ccc"
                placeholder="First Name"
              />
            </FormControl>
            <FormControl
              isRequired={true}
              isInvalid={formErrors.lastname}
              width={{ base: "100%", xl: "48%" }}
            >
              <Input
                value={formData.lastname}
                color="#666"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                height="52px"
                backgroundColor="white"
                border="1px solid #ccc"
                onChange={(e) => handleFormChanged("lastname", e.target.value)}
                type="text"
                placeholder="Last Name"
              />
            </FormControl>
            <FormControl
              isRequired={true}
              isInvalid={formErrors.email}
              width={{ base: "100%", xl: "48%" }}
            >
              <Input
                onChange={(e) => handleFormChanged("email", e.target.value)}
                type="email"
                value={formData.email}
                color="#666"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                height="52px"
                backgroundColor="white"
                border="1px solid #ccc"
                placeholder="Email"
              />
            </FormControl>
            <FormControl
              isRequired={true}
              isInvalid={formErrors.mobilephone}
              width={{ base: "100%", xl: "48%" }}
            >
              <Input
                value={formData.mobilephone}
                type="number"
                color="#666"
                onChange={(e) =>
                  handleFormChanged("mobilephone", e.target.value)
                }
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                height="52px"
                backgroundColor="white"
                border="1px solid #ccc"
                placeholder="Phone Number"
              />
            </FormControl>
            <FormControl
              isRequired={true}
              isInvalid={formErrors.company}
              width={{ base: "100%", xl: "48%" }}
            >
              <Input
                value={formData.company}
                type="text"
                color="#666"
                fontSize="14px"
                fontStyle="normal"
                onChange={(e) => handleFormChanged("company", e.target.value)}
                fontWeight="500"
                lineHeight="normal"
                height="52px"
                backgroundColor="white"
                border="1px solid #ccc"
                placeholder="Company Name"
              />
            </FormControl>
            <FormControl
              isRequired={true}
              isInvalid={formErrors.company_type}
              width={{ base: "100%", xl: "48%" }}
            >
              <Select
                color="#666"
                fontSize="14px"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                height="52px"
                backgroundColor="white"
                border="1px solid #ccc"
                value={formData.company_type}
                placeholder="Company Type"
                onChange={(e) =>
                  handleFormChanged("company_type", e.target.value)
                }
              >
                <option>GP / Sponsor</option>
                <option>LP / Investor</option>
                <option>other</option>
              </Select>
            </FormControl>
          </HStack>
          <Box>
            <Text
              paddingY="30px"
              color="#333"
              fontSize="20px"
              fontStyle="normal"
              fontWeight="500"
              lineHeight="normal"
            >
              Asset Class{" "}
            </Text>
            <HStack>
              <RadioGroup
                onChange={(e) => handleFormChanged("assest_class", e)}
              >
                <Stack flexWrap="wrap" width="100%" gap="30px" direction="row">
                  <Radio
                    isInvalid={formErrors.assest_class}
                    value="Real Estate"
                    size="lg"
                    colorScheme="transparent"
                    borderColor="#FD8534"
                    borderRadius="3px"
                    color="#FD8534 !important"
                  >
                    <Text
                      color=" #666"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="normal"
                    >
                      Real Estate
                    </Text>
                  </Radio>
                  <Radio
                    isInvalid={formErrors.assest_class}
                    value="Venture Capital"
                    size="lg"
                    colorScheme="transparent"
                    borderColor="#FD8534"
                    borderRadius="3px"
                    color="#FD8534 !important"
                  >
                    <Text
                      color=" #666"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="normal"
                    >
                      Venture Capital
                    </Text>
                  </Radio>
                  <Radio
                    isInvalid={formErrors.assest_class}
                    size="lg"
                    colorScheme="transparent"
                    borderColor="#FD8534"
                    borderRadius="3px"
                    color="#FD8534 !important"
                    value="Private Equity"
                  >
                    <Text
                      color=" #666"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="normal"
                    >
                      Private Equity
                    </Text>
                  </Radio>
                  <Radio
                    isInvalid={formErrors.assest_class}
                    size="lg"
                    colorScheme="transparent"
                    borderColor="#FD8534"
                    borderRadius="3px"
                    color="#FD8534 !important"
                    value="Private Debt"
                  >
                    <Text
                      color=" #666"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="normal"
                    >
                      Private Debt
                    </Text>
                  </Radio>
                  <Radio
                    isInvalid={formErrors.assest_class}
                    value="Other"
                    size="lg"
                    colorScheme="transparent"
                    borderColor="#FD8534"
                    borderRadius="3px"
                    color="#FD8534 !important"
                  >
                    <Text
                      color=" #666"
                      fontSize="16px"
                      fontStyle="normal"
                      fontWeight="400"
                      lineHeight="normal"
                    >
                      Other
                    </Text>
                  </Radio>
                </Stack>
              </RadioGroup>
            </HStack>
            <Box paddingTop="40px">
              <Button
                width="inherit"
                borderRadius="5px"
                fontSize="16px"
                color="white"
                fontStyle="normal"
                fontWeight="500"
                lineHeight="normal"
                paddingX="26px"
                paddingY="13px"
                backgroundColor="#FD8534"
                _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
                colorScheme="white"
                onClick={handleSubmitForm}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </Box>
  );
};
export default LetsTalk;
