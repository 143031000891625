import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/header";
import Banner from "./components/banner";
import Advantages from "./components/advantages";
import FinanceHandling from "./components/financeHandling";
import PaymentFlow from "./components/paymentFlow";
import Footer from "../../components/footer";

const AkruAdmin = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <Advantages />
      <FinanceHandling />
      <PaymentFlow />
      <Footer />
    </Box>
  );
};

export default AkruAdmin;
