import React from "react";

import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import TalkToUsUtil from "../../../../../../components/talkToUsSub";

const TalkToUsSub = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });

  return (
    <Box paddingY={{ base: "60px", xl: "120px" }} backgroundColor="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          alignItems="start"
          justifyContent="space-between"
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Box width={{ base: "100%", xl: "55%" }}>
            <Text
              color="#666"
              fontSize="24px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="40px"
            >
              Join us in exploring how our AKRU Alts. Custom Private Markets
              Platform can revolutionize your approach to asset management. Our
              platform is designed to infuse your investment strategy with
              unparalleled customization, efficiency, and robust security. Dive
              into the world of advanced asset management where cost-efficient
              digitization meets the clarity of blockchain-driven transparency.
              Our team is dedicated to showing you how AKRU can tailor its
              solutions to your unique financial goals, ensuring a personalized
              and efficient asset management experience. Engage with us to shape
              the future of your asset management journey.
            </Text>
          </Box>
          <Box
            marginTop={{ base: "40px", xl: "0px" }}
            backgroundColor="#FFF3EB"
            borderRadius="10px"
            paddingX="30px"
            paddingY="40px"
            width={{ base: "100%", xl: "40%" }}
          >
            <TalkToUsUtil formName="Akru Alts Talk to Us" />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
export default TalkToUsSub;
