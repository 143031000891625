import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import BannerImage from "../../../../assets/images/blog/Rectangle 5807.png";
import { Link } from "react-router-dom";

const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box backgroundColor=" #F9F9F9">
      {/* text-image */}
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Box>
          <Flex
            flexDirection="column"
            alignItems="center"
            paddingY={{ base: "40px", xl: "80px" }}
          >
            <Text
              textAlign="center"
              color=" #333"
              fontSize={{ base: "30px", xl: " 48px" }}
              fontStyle="normal"
              fontWeight=" 500"
              lineHeight="70px"
            >
              Blog
            </Text>
          </Flex>
          <Link to="/blog-details">
            <Flex justifyContent="center">
              <Box
                backgroundImage={BannerImage}
                backgroundPosition="center"
                backgroundSize="conver"
                backgroundRepeat="no-repeat"
                width="100%"
                height="527px"
              >
                <Flex
                  height="inherit"
                  flexDirection="column"
                  justifyContent="end"
                  p="40px"
                >
                  <Text
                    width={{ base: "fit-content", xl: "600px" }}
                    textAlign="start"
                    color=" #fff"
                    fontSize={{ base: "20px", xl: " 30px" }}
                    fontStyle="normal"
                    fontWeight={{ base: "600", xl: " 500" }}
                    lineHeight={{ base: "40px", xl: "50px" }}
                  >
                    How Cross-Chain Interoperability Can Break Down
                    Institutional Data Silos
                  </Text>
                  <Text
                    width={{ base: "fit-content", xl: "780px" }}
                    paddingTop="20px"
                    textAlign="start"
                    fontSize={{ base: "12px", xl: " 16px" }}
                    color=" #FFF"
                    fontStyle="normal"
                    fontWeight=" 400"
                    lineHeight={{ base: "30px", xl: "35px" }}
                  >
                    The financial landscape, particularly in private markets, is
                    witnessing a transformative shift with the integration of
                    smart contracts. These advanced technological tools are
                    reshaping how credit servicing is managed, bringing about a
                    new era of efficiency, transparency, and security.
                  </Text>
                </Flex>
              </Box>
              {/* <Image src={BannerImage} alt="Banner Image" /> */}
            </Flex>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
