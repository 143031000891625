import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/header";
import Banner from "./components/banner";
import InvestorManagment from "./components/inestorManagment";
import Communication from "./components/communication";
import LeadManagment from "./components/leadManagment";
import EffectiveInvestor from "./components/effectiveInvestor";
import Footer from "../../components/footer";

const AkruInvestorManagment = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <InvestorManagment />
      <Communication />
      <LeadManagment />
      <EffectiveInvestor />
      <Footer />
    </Box>
  );
};

export default AkruInvestorManagment;
