import {
  Box,
  Button,
  Divider,
  Flex,
  Image,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import EfficientImage1 from "../../../../assets/images/akruServicing/Rectangle 5809.png";
import EfficientImage2 from "../../../../assets/images/akruServicing/Group (2).png";
import EfficientImage3 from "../../../../assets/images/akruServicing/Group (3).png";
import EfficientImage4 from "../../../../assets/images/akruServicing/Group (4).png";
import Fade from "react-reveal/Fade";
const Efficient = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex paddingTop="100px" justifyContent="center">
          <Box
            backgroundImage={EfficientImage1}
            backgroundPosition="center"
            backgroundSize="cover"
            backgroundRepeat="no-repeat"
            height={{ base: "700px", xl: "527px" }}
            width="100%"
            backgroundAttachment="fixed"
          >
            <Flex
              flexDirection="column"
              height="inherit"
              alignItems="center"
              justifyContent="center"
              paddingX={{ base: "10px", xl: "0px" }}
            >
              <Text
                width={{ base: "fit-content", xl: "615px" }}
                color="#FFF"
                textAlign="center"
                fontSize={{ base: "24px", xl: "36px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: " 500" }}
                lineHeight={{ base: "40px", xl: "50px" }}
              >
                Unveil the Secrets of AKRU Servicing
              </Text>
              <Text
                paddingTop="10px"
                width={{ base: "fit-content", xl: "685px" }}
                color="#FFF"
                textAlign="center"
                fontSize={{ base: "14px", xl: "16px" }}
                fontStyle="normal"
                fontWeight=" 500"
                lineHeight="30px"
              >
                Embark on your credit management journey with our exclusive
                product sheet. Delve into the specifics of how we transform
                credit servicing, from smart contract automation to precision in
                processing. Download now and gain comprehensive insights into
                what sets AKRU Servicing apart.
              </Text>
              <Link href="/download-page-servicing">
                <Button
                  marginTop="40px"
                  width="inherit"
                  borderRadius="5px"
                  fontSize={{ base: "14px", xl: "16px" }}
                  color="white"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  paddingX="26px"
                  paddingY="13px"
                  backgroundColor="#FD8534"
                  _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
                  colorScheme="white"
                >
                  Download Now
                </Button>
              </Link>
            </Flex>
          </Box>
        </Flex>
        {/* section-1 */}
        <Flex
          paddingTop="100px"
          justifyContent="space-evenly"
          alignItems="center"
          flexDirection={{ base: "column", xl: "row" }}
        >
          {" "}
          <Flex
            justifyContent="center"
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Fade left>
              <Image h="150px" src={EfficientImage2} />
            </Fade>
          </Flex>
          <Fade right>
            <Box
              w={{ base: "100%", xl: "50%" }}
              paddingTop={{ base: "50px", xl: "0px" }}
            >
              <Text
                width={{ base: "fit-content", xl: "300px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Efficient and Compliant
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "620px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Streamline servicing operations while adhering to regulatory
                compliance, offering a secure and reliable financial
                environment.
              </Text>
            </Box>
          </Fade>
        </Flex>
        <Divider
          border="1px solid #CCC"
          marginY={{ base: "60px", xl: "120px" }}
        />
        {/* section-2 */}
        <Flex
          flexDirection={{ base: "column-reverse", xl: "row" }}
          paddingTop="0px"
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Fade left>
            <Box paddingTop={{ base: "50px", xl: "0px" }}>
              <Text
                width={{ base: "fit-content", xl: "547px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Comprehensive Deal Structure Management
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Offer solutions for managing complex credit structures, catering
                to diverse investment needs and strategies.
              </Text>
            </Box>
          </Fade>
          <Flex
            paddingTop={{ base: "50px", xl: "0px" }}
            position="relative"
            width="fill-available"
            justifyContent="center"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Fade right>
              <Image h="150px" src={EfficientImage3} />
            </Fade>
          </Flex>
        </Flex>
        <Divider
          border="1px solid #CCC"
          marginY={{ base: "60px", xl: "120px" }}
        />
        {/* section-3 */}
        <Flex
          paddingBottom="100px"
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Flex
            width="fill-available"
            justifyContent="center"
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Fade left>
              <Image h="150px" src={EfficientImage4} />
            </Fade>
          </Flex>
          <Fade right>
            <Box paddingTop={{ base: "50px", xl: "0px" }}>
              <Text
                width={{ base: "fit-content", xl: "576px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Optimized Capital Flow Tracking
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "602px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Track capital allocation and utilization, ensuring optimized
                management of capital flows.
              </Text>
            </Box>
          </Fade>
        </Flex>
      </Box>
    </Box>
  );
};

export default Efficient;
