import { Box, Divider, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className="bg-akruAltsSub">
        <Box
          height="fill-available"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 5.29%, #000 100%);"
        >
          <Box className={isXLScreen ? "container" : "container-responsive"}>
            <Flex height="400px" alignItems="center">
              <Flex justifyContent="center" width="100%">
                <Text
                  width="832px"
                  textAlign="center"
                  color=" #FFF"
                  fontSize={{ base: "30px", xl: " 48px" }}
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight={{ base: "50px", xl: "70px" }}
                >
                  Unlock the Future of Customized Asset Management
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box backgroundColor="#F9F9F9">
        <Box
          className={isXLScreen ? "container" : "container-responsive"}
          paddingTop="80px"
        >
          <Text
            paddingBottom="80px"
            textAlign="start"
            color=" #666"
            fontSize={{ base: "16px", xl: " 24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight={{ base: "30px", xl: "40px" }}
          >
            With our Alts. Custom Private Markets platform, innovation meets
            personalized asset management. Tailored for modern investors, our
            platform redefines asset management with a focus on customization,
            cost efficiency, and enhanced security, ensuring a new level of
            transparency in your financial dealings.
          </Text>
          <Divider border="1px solid #ccc" />
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
