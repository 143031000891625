import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import Fade from "react-reveal/Fade";
import EffectiveInvestorImage1 from "../../../../assets/images/AkruInvestorManagment/Group 3890.png";
import LeadManagmentImage2 from "../../../../assets/images/AkruInvestorManagment/Group 3884.png";
import ThePowerOfIm from "../thePowerOfIm";

const LeadManagment = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box backgroundColor="#f9f9f9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        {/* section-2 */}
        <Flex
          paddingTop={{ base: "0px", xl: "120px" }}
          flexDirection={{ base: "column-reverse", xl: "row" }}
          paddingBottom={{ base: "60px", xl: "120px" }}
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Box
            position="relative"
            paddingTop={{ base: "50px", xl: "0px" }}
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={LeadManagmentImage2}
            />
          </Box>
          <Fade right>
            <Box>
              <Text
                paddingTop={{ base: "50px", xl: "0px" }}
                width={{ base: "fit-content", xl: "178px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Deal Pipeline
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Track and manage deal flow from initiation to closure with our
                dynamic deal pipeline.
              </Text>
            </Box>
          </Fade>
        </Flex>
      </Box>
      <ThePowerOfIm />
      {/* section-1 */}
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          paddingY={{ base: "60px", xl: "100px" }}
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Fade left>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "390px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Efficient Investor Onboarding
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Robust identity verification integrating KYC/AML checks for a
                secure, streamlined onboarding process.
              </Text>
            </Box>
          </Fade>
          <Box
            paddingTop={{ base: "50px", xl: "0px" }}
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={EffectiveInvestorImage1}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default LeadManagment;
