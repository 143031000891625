import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../../../components/header";
import Banner from "./components/banner";
import KeyBenifits from "./components/keyBenifits";
import LetsTalk from "../../../Home/components/homeSubPage/components/letsTalkHomeSub";
import Footer from "../../../../components/footer";
const LearnMoreIm = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <KeyBenifits />
      <LetsTalk  formName="Akru IM Learn More"/>
      <Footer />
    </Box>
  );
};

export default LearnMoreIm;
