import { Box } from "@chakra-ui/react";
import React from "react";
import Header from "../../components/header";
import Banner from "./compoenents/banner";
import BlogCards from "./compoenents/blogCards";
import Footer from "../../components/footer";

const Blog = () => {
  return (
    <Box>
      <Header />
      <Banner />
      <BlogCards />
      <Footer />
    </Box>
  );
};

export default Blog;
