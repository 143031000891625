import {
  Box,
  Button,
  Flex,
  FormControl,
  Input,
  Text,
  Textarea,
  VStack,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import SimpleMap from "../../../../components/googleMap";
import axios from "axios";
import ShowToast from "../../../../components/showToast";

const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });

  const [formData, setFormData] = useState({
    firstname: "",
    email: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const requiredFields = ["firstname", "email", "message"];
  const validateForm = () => {
    const errors = {};

    requiredFields.forEach((fieldName) => {
      const formattedFieldName = fieldName.replace("_", " ");

      if (!formData[fieldName] || !formData[fieldName].trim()) {
        errors[fieldName] = `${
          formattedFieldName.charAt(0).toUpperCase() +
          formattedFieldName.slice(1)
        } is required`;
      }
    });
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      errors.email = "Enter a valid email address";
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleFormChanged = (name, value) => {
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: undefined,
    }));
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const isValid = validateForm();
    console.log("trying");
    if (isValid) {
      try {
        const formattedData = {
          fields: Object.keys(formData).map((fieldName) => ({
            objectTypeId:  "0-1",
            name: fieldName,
            value: formData[fieldName],
          })),
        };

        setFormData({
          firstname: "",
          email: "",
          message: "",
        });

        const response = await axios.post(
          "https://api.hsforms.com/submissions/v3/integration/submit/20164312/279ebef3-d270-4aad-aba1-fb735cbd9428",
          JSON.stringify(formattedData),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        if (response) {
          setFormData({
            firstname: "",
            email: "",
            message: "",
          });

          console.log(response);
          if (response) {
            ShowToast(
              "success",
              "Thank you for reaching out to us! Our team will be in contact with you shortly."
            );
          }
        }
      } catch (error) {
        console.error("error", error);
        ShowToast("error", error);
      }
    } else {
      ShowToast("error", "Please fill in all required fields.");
    }
  };
  return (
    <Box backgroundColor=" #F9F9F9">
      {/* text-image */}
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Box>
          <Flex
            flexDirection="column"
            alignItems="center"
            paddingY={{ base: "40px", xl: "80px" }}
          >
            <Text
              textAlign="center"
              color=" #333"
              fontSize={{ base: "30px", xl: " 48px" }}
              fontStyle="normal"
              fontWeight=" 500"
              lineHeight="70px"
            >
              Contact Us
            </Text>
          </Flex>
          <Flex justifyContent="center">
            <SimpleMap />
            {/* <Image src={BannerImage} alt="Banner Image" /> */}
          </Flex>
          <Flex
            flexDirection={{ base: "column", xl: "row" }}
            justifyContent="space-between"
            paddingY={{ base: "40px", xl: "80px" }}
          >
            <Flex
              flexDirection="column"
              alignItems="start"
              paddingBottom="20px"
            >
              <Text
                textAlign={{ base: "center", xl: "start" }}
                color=" #333"
                fontSize={{ base: "18px", xl: " 24px" }}
                fontStyle="normal"
                fontWeight=" 500"
                lineHeight="40px"
              >
                Get in Touch
              </Text>
              <Text
                textAlign="start"
                color=" #666"
                fontSize={{ base: "14px", xl: " 16px" }}
                fontStyle="normal"
                fontWeight=" 400"
                lineHeight="40px"
              >
                3825 Edwards Rd, Suite #103, Cincinnati, OH 45209.
              </Text>
              <Text
                textAlign="start"
                color=" #666"
                fontSize={{ base: "14px", xl: " 16px" }}
                fontStyle="normal"
                fontWeight=" 400"
                lineHeight="40px"
              >
                +1 888 966 AKRU (2578)
              </Text>
              <Text
                textAlign="start"
                color=" #666"
                fontSize={{ base: "14px", xl: " 16px" }}
                fontStyle="normal"
                fontWeight=" 400"
                lineHeight="40px"
              >
                info@akru.com
              </Text>
            </Flex>
            <VStack
              alignItems="start"
              gap="20px"
              width={{ base: "100%", xl: "35%" }}
            >
              <FormControl
                isRequired={true}
                isInvalid={formErrors.firstname}
                width="100%"
              >
                <Input
                  type="text"
                  onChange={(e) =>
                    handleFormChanged("firstname", e.target.value)
                  }
                  value={formData.firstname}
                  color="#666"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  height="52px"
                  backgroundColor="white"
                  border="1px solid #ccc"
                  placeholder="Name"
                />
              </FormControl>
              <FormControl
                isRequired={true}
                isInvalid={formErrors.email}
                width="100%"
              >
                <Input
                  onChange={(e) => handleFormChanged("email", e.target.value)}
                  type="email"
                  value={formData.email}
                  color="#666"
                  fontSize="14px"
                  fontStyle="normal"
                  fontWeight="500"
                  lineHeight="normal"
                  height="52px"
                  backgroundColor="white"
                  border="1px solid #ccc"
                  placeholder="Email"
                />
              </FormControl>
              <FormControl
                width={{ base: "100%", xl: "100%" }}
                isRequired={true}
                isInvalid={formErrors.message}
              >
                <Textarea
                  value={formData.message}
                  color="#666"
                  fontSize="14px"
                  fontStyle="normal"
                  onChange={(e) => handleFormChanged("message", e.target.value)}
                  fontWeight="500"
                  lineHeight="normal"
                  height="auto"
                  rows={9}
                  backgroundColor="white"
                  border="1px solid #ccc"
                  placeholder="Type Message"
                />
              </FormControl>
              <Box >
                <Button
                  borderRadius="5px"
                  fontSize="16px"
                  color="white"
                  fontStyle="normal"
                  fontWeight="700"
                  lineHeight="normal"
                  paddingX="26px"
                  paddingY="13px"
                  backgroundColor="#FD8534"
                  _hover={{ backgroundColor: "#FD8534CC" }}
                  colorScheme="white"
                  onClick={handleSubmitForm}
                >
                  Submit
                </Button>
              </Box>
            </VStack>
          </Flex>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
