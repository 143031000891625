import { Box, Divider, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className="bg-akruImSub">
        <Box
          height="fill-available"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 5.29%, #000 100%);"
        >
          <Box className={isXLScreen ? "container" : "container-responsive"}>
            <Flex height="400px" alignItems="center">
              <Flex justifyContent="center" width="100%">
                <Text
                  width="832px"
                  textAlign="center"
                  color=" #FFF"
                  fontSize={{ base: "30px", xl: " 48px" }}
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight={{ base: "50px", xl: "70px" }}
                >
                  Investor Management Reimagined
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
      <Box backgroundColor="#F9F9F9">
        <Box
          className={isXLScreen ? "container" : "container-responsive"}
          paddingTop="80px"
        >
          <Text
            paddingBottom="80px"
            textAlign="start"
            color=" #666"
            fontSize={{ base: "16px", xl: " 24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight={{ base: "30px", xl: "40px" }}
          >
            AKRU IM is a comprehensive platform designed to elevate the way you
            manage your investors. At the heart of AKRU IM lies a commitment to
            efficient, compliant, and engaging investor management. Our platform
            is your gateway to a streamlined, insightful, and secure investor
            management experience.
          </Text>
          <Divider border="1px solid #ccc" />
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
