import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className="bg-image-blogDetails4">
        <Box
          height="fill-available"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 5.29%, #000 100%);"
        >
          <Box className={isXLScreen ? "container" : "container-responsive"}>
            <Flex
              height="1002px"
              paddingBottom={{ base: "50px", xl: "100px" }}
              justifyContent="end"
              flexDirection="column"
            >
              <Flex
                flexDirection="column"
                alignItems="start"
                justifyContent="end"
                paddingTop={{ base: "40px", xl: "80px" }}
                paddingBottom={{ base: "40px", xl: "30px" }}
              >
                <Text
                  textAlign="start"
                  color=" #FFF"
                  fontSize={{ base: "30px", xl: " 48px" }}
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight={{ base: "50px", xl: "70px" }}
                >
                  Beyond Traditional Investing - Blockchain and AI’s Impact on
                  Private Markets
                </Text>
              </Flex>
              <Box>
                <Text
                  textAlign="start"
                  color=" #FFF"
                  fontSize={{ base: "18px", xl: " 24px" }}
                  fontStyle="normal"
                  fontWeight=" 400"
                  lineHeight="40px"
                >
                  14th Jan, 2024 | Tech | 2 min read
                </Text>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
