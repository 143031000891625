import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";

const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className="bg-image-network">
        <Box
          height="fill-available"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 5.29%, #000 100%);"
        >
          <Box className={isXLScreen ? "container" : "container-responsive"}>
            <Flex
              height="1002px"
              paddingBottom={{ base: "50px", xl: "100px" }}
              justifyContent="end"
              flexDirection="column"
            >
              <Flex
                flexDirection="column"
                alignItems="start"
                justifyContent="end"
                paddingTop={{ base: "40px", xl: "80px" }}
                paddingBottom={{ base: "40px", xl: "30px" }}
              >
                <Text
                  textAlign="start"
                  color=" #FFF"
                  fontSize={{ base: "30px", xl: " 48px" }}
                  fontStyle="normal"
                  fontWeight={{ base: "600", xl: " 500" }}
                  lineHeight={{ base: "40px", xl: "50px" }}
                >
                  A On-Fi Network
                </Text>
                <Text
                  textAlign="start"
                  color=" #FFF"
                  fontSize={{ base: "14px", xl: " 20px" }}
                  fontStyle="normal"
                  fontWeight=" 400"
                  lineHeight={{ base: "30px", xl: "35px" }}
                >
                  Unlock a New Financial Paradigm
                </Text>
              </Flex>
              <Box>
                <Text
                  color="#FFF"
                  textAlign="start"
                  fontSize={{ base: "14px", xl: "16px" }}
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight="30px"
                >
                  In today's world, institutions often find themselves trapped
                  in silos, operating in isolation. AKRU's On-Fi Network
                  disrupts this norm, seamlessly connecting institutions and
                  tearing down silos. This paves the way for a future where
                  financial interactions know no borders, while ensuring
                  enhanced efficiency, compliance, cost-saving, and scalability.
                </Text>
              </Box>
              <Box paddingTop="30px">
                <Link href="/learn-more-a-onfi">
                  <Button
                    borderRadius="5px"
                    fontSize={{ base: "14px", xl: "16px" }}
                    color="white"
                    fontStyle="normal"
                    fontWeight="700"
                    lineHeight="normal"
                    paddingX="26px"
                    paddingY="13px"
                    backgroundColor="#FD8534"
                    _hover={{ backgroundColor: "#FD9D5D", color: "#FFF" }}
                    colorScheme="white"
                  >
                    Learn More
                  </Button>
                </Link>
              </Box>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
