import { toast } from '../../App';

const ShowToast = (status, title, description) => {
  return toast({
    status,
    title,
    description,
    position: 'top-right',
  });
};

export default ShowToast;