import { Box, Image, Link, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import BlogDetailsImage1 from "../../../../assets/images/blogDetails/Rectangle 5808 (1).png";
import BlogDetailsImage2 from "../../../../assets/images/blogDetails/Rectangle 5841 (1).png";
const BlogDetailsSub1 = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });

  return (
    <Box className={isXLScreen ? "container" : "container-responsive"}>
      <Box paddingTop="100px">
        <Text
          color=" #666"
          fontSize={{ base: "16px", xl: "24px" }}
          fontStyle="normal"
          fontWeight="400"
          lineHeight="40px"
        >
          The financial landscape, particularly in private markets, is
          witnessing a transformative shift with the integration of smart
          contracts. These advanced technological tools are reshaping how credit
          servicing is managed, bringing about a new era of efficiency,
          transparency, and security.
        </Text>
        <Text
          paddingTop="50px"
          color=" #666"
          fontSize={{ base: "16px", xl: "24px" }}
          fontStyle="normal"
          fontWeight="400"
          lineHeight="40px"
        >
          At the core of this transformation are smart contracts, self-executing
          contracts with the terms of the agreement embedded into lines of code.
          Operating on blockchain technology, they offer a decentralized,
          transparent, and immutable ledger. This{" "}
          <Link
            color="blue"
            textDecor="underline"
            href="https://globacap.com/content-hub/articles/smart-contracts-in-finance/"
            target="_blank"
          >
            {" "}
            innovation
          </Link>{" "}
          is crucial for automating contract execution when predefined
          conditions are met, eliminating the need for intermediaries and
          reducing potential errors and oversight, especially in complex credit
          agreements typical of private markets​​​​.
        </Text>

        <Box paddingY="60px">
          <Image src={BlogDetailsImage1} />
        </Box>
        <Box paddingBottom="60px">
          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            In the realm of credit transactions, adherence to regulations like
            KYC (Know Your Customer) and AML (Anti-Money Laundering) is
            paramount. Smart contracts automate these compliance checks,
            securing the integrity of credit services in private markets. This
            technological leap not only guarantees compliance but also
            drastically cuts down the extensive manual effort traditionally
            required, thereby{" "}
            <Link
              color="blue"
              textDecor="underline"
              href="https://globacap.com/content-hub/articles/smart-contracts-in-finance/"
              target="_blank"
            >
              {" "}
              enhancing
            </Link>{" "}
            the efficiency of the entire process​​​​.
          </Text>
          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Syndicated loans, which are prevalent in private markets, involve
            numerous stakeholders and intricate agreements. Here, smart
            contracts bring a level of efficiency previously unattainable. They
            automate settlement processes and enforce the agreed terms, thus
            mitigating risks and enhancing operational efficiency. This
            automated approach ensures that all parties involved adhere to their
            obligations, streamlining the entire syndicated loan process​​.
          </Text>

          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Another significant area where smart contracts are making an{" "}
            <Link
              color="blue"
              textDecor="underline"
              href="https://www2.deloitte.com/us/en/pages/finance/articles/cfo-insights-getting-smart-contracts.html"
              target="_blank"
            >
              {" "}
              impact
            </Link>
            is in trade clearing and settlement in credit transactions. By
            automating these processes, smart contracts reduce the time and
            costs associated with manual reconciliations and approvals. This not
            only makes credit transactions more efficient but also adds a layer
            of accuracy and speed that is vital in the fast-paced financial
            world​​.
          </Text>
        </Box>

        <Box paddingY="60px">
          <Image src={BlogDetailsImage2} />
        </Box>
        <Box>
          <Text
            paddingTop="30px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            The{" "}
            <Link
              color="blue"
              textDecor="underline"
              href="https://www2.deloitte.com/us/en/pages/finance/articles/cfo-insights-getting-smart-contracts.html"
              target="_blank"
            >
              {" "}
              deployment
            </Link>
            of smart contracts in credit servicing introduces numerous benefits.
            It leads to increased speed, higher accuracy, and lower execution
            risk. Moreover, it curtails the need for intermediaries, leading to
            significant cost savings and enhanced reliability and efficiency of
            credit services. These improvements are particularly impactful in
            private markets, where the traditional complexities of financial
            transactions can be streamlined effectively​​.
          </Text>
          <Text
            paddingTop="60px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            The ongoing adoption and development of smart contracts in credit
            servicing indicate a trend towards more streamlined, transparent,
            and secure financial transactions in private markets. This
            technology is poised to redefine the management and servicing of
            credit, offering robust and adaptable financial services. As the
            technology matures, it's expected to become a standard component in
            the credit servicing toolkit, revolutionizing the sector in ways
            previously unimagined.
          </Text>
          <Text
            paddingTop="60px"
            color=" #666"
            fontSize={{ base: "16px", xl: "24px" }}
            fontStyle="normal"
            fontWeight="400"
            lineHeight="40px"
          >
            Smart contracts are not just a technological innovation; they are a
            pivotal force in reshaping the landscape of credit servicing in
            private markets. By automating complex processes, ensuring
            compliance, and enhancing operational efficiency, they represent a
            significant leap forward in the financial domain. As the industry
            continues to evolve, smart contracts stand at the forefront,
            heralding a new era of efficiency and transparency in financial
            services.
          </Text>
        </Box>
      </Box>
    </Box>
  );
};

export default BlogDetailsSub1;
