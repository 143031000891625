import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";

const Banner = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className="bg-dataAnalyzerSub">
        <Box
          height="fill-available"
          background="linear-gradient(180deg, rgba(0, 0, 0, 0.00) 5.29%, #000 100%);"
        >
          <Box className={isXLScreen ? "container" : "container-responsive"}>
            <Flex height="400px" alignItems="center">
              <Flex justifyContent="center" width="100%">
                <Text
                  width={{ base: "100%", xl: "640px" }}
                  textAlign="center"
                  color=" #FFF"
                  fontSize={{ base: "30px", xl: " 48px" }}
                  fontStyle="normal"
                  fontWeight=" 500"
                  lineHeight={{ base: "50px", xl: "70px" }}
                >
                  Redefine Data Analysis with AKRU Document Analyzer
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Banner;
