import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import Fade from "react-reveal/Fade";
const Strategy = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box paddingY="80px">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          flexDirection={{ base: "column", xl: "row" }}
          cursor="pointer"
          paddingY="30px"
          paddingX={{ base: "20px", xl: "40px" }}
          backgroundColor="#FD8534"
          justifyContent="space-between"
          alignItems="center"
        >
          <Fade left>
            <Text
              color="#FFF"
              fontSize={{ base: "24px", xl: "32px" }}
              fontStyle="normal"
              fontWeight={{ base: "600", xl: "500" }}
              lineHeight="28px"
            >
              Vision
            </Text>
          </Fade>
          <Fade right>
            <Text
              width={{ base: "fit-content", xl: "505px" }}
              color="#FFF"
              fontSize={{ bse: "14px", xl: "16px" }}
              fontStyle="normal"
              fontWeight="400"
              lineHeight={{ base: "20px", xl: "24px" }}
              paddingTop={{ base: "10px", xl: "0px" }}
            >
              AKRU On-Fi envisions a future where private markets are
              transformed by blockchain and natural language processing. Our
              goal is to make finance more accessible, transparent, and secure
              for all.
            </Text>
          </Fade>
        </Flex>

        <Flex
          flexDirection={{ base: "column", xl: "row" }}
          cursor="pointer"
          paddingY="30px"
          paddingX={{ base: "20px", xl: "40px" }}
          backgroundColor="#FA9A5A"
          justifyContent="space-between"
          alignItems="center"
        >
          <Fade left>
            <Text
              color="#FFF"
              fontSize={{ base: "24px", xl: "32px" }}
              fontStyle="normal"
              fontWeight={{ base: "600", xl: "500" }}
              lineHeight="28px"
            >
              Mission
            </Text>
          </Fade>
          <Fade right>
            <Text
              width={{ base: "fit-content", xl: "505px" }}
              color="#FFF"
              fontSize={{ bse: "14px", xl: "16px" }}
              fontStyle="normal"
              fontWeight="400"
              lineHeight={{ base: "20px", xl: "24px" }}
              paddingTop={{ base: "10px", xl: "0px" }}
            >
              At AKRU On-Fi, our mission is to redefine private markets through
              blockchain technology and natural language processing. We're
              committed to enhancing transparency, security, and efficiency in
              finance, making it more accessible and reliable for everyone.
            </Text>
          </Fade>
        </Flex>
        <Flex
          flexDirection={{ base: "column", xl: "row" }}
          cursor="pointer"
          paddingY="30px"
          paddingX={{ base: "20px", xl: "40px" }}
          backgroundColor="#F7AF7E"
          justifyContent="space-between"
          alignItems="center"
        >
          <Fade left>
            <Text
              color="#FFF"
              fontSize={{ base: "24px", xl: "32px" }}
              fontStyle="normal"
              fontWeight={{ base: "600", xl: "500" }}
              lineHeight="28px"
            >
              Story
            </Text>
          </Fade>
          <Fade right>
            <Text
              width={{ base: "fit-content", xl: "505px" }}
              color="#FFF"
              fontSize={{ bse: "14px", xl: "16px" }}
              fontStyle="normal"
              fontWeight="400"
              lineHeight={{ base: "20px", xl: "24px" }}
              paddingTop={{ base: "10px", xl: "0px" }}
            >
              AKRU On-Fi envisions a future where private markets are
              transformed by blockchain and natural language processing. Our
              goal is to make finance more accessible, transparent, and secure
              for all.
            </Text>
          </Fade>
        </Flex>
      </Box>
    </Box>
  );
};

export default Strategy;
