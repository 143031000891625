import React from "react";
import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import TalkToUsUtils from "../../../../../../components/talkToUsSub";

const TalkToUsSub = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box paddingY={{ base: "60px", xl: "120px" }} backgroundColor="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          alignItems="start"
          justifyContent="space-between"
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Box width={{ base: "100%", xl: "55%" }}>
            <Text
              color="#666"
              fontSize="24px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="40px"
            >
              Step into the future of credit servicing with AKRU Servicing. Our
              platform is crafted to streamline and enhance the management of
              complex credit transactions, including Asset-Backed and
              Mortgage-Backed Securities. Learn how our smart contract
              automation, precision in processing, and comprehensive deal
              structure management can simplify your credit servicing tasks. Our
              team is ready to guide you through every aspect of our platform,
              demonstrating how AKRU Servicing can optimize your credit
              management and drive success in the financial world.
            </Text>
          </Box>
          <Box
            marginTop={{ base: "40px", xl: "0px" }}
            backgroundColor="#FFF3EB"
            borderRadius="10px"
            paddingX="30px"
            paddingY="40px"
            width={{ base: "100%", xl: "40%" }}
          >
            <TalkToUsUtils formName="Akru Servicing Talk to Us" />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
export default TalkToUsSub;
