import {
  Box,
  Flex,
  HStack,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import React from "react";
import Service1 from "../../../../assets/images/home/Group 3925.png";
import Service2 from "../../../../assets/images/home/Group 3926.png";
import Service3 from "../../../../assets/images/home/Group 3927.png";
import Service4 from "../../../../assets/images/home/Group 3928.png";
import Service5 from "../../../../assets/images/home/Group 3929.png";
import Service6 from "../../../../assets/images/home/Group 3930.png";
import Service7 from "../../../../assets/images/home/Group 3931.png";
import Service8 from "../../../../assets/images/home/Group 3932.png";
import Service9 from "../../../../assets/images/home/Group 3933.png";
import Service10 from "../../../../assets/images/home/Group 3934.png";
import Service11 from "../../../../assets/images/home/Group 3935.png";
import Service12 from "../../../../assets/images/home/Group 3936.png";
import Service13 from "../../../../assets/images/home/Group 3937.png";
import Service14 from "../../../../assets/images/home/Group 3938.png";
import Service15 from "../../../../assets/images/home/Group 3939.png";
import Service16 from "../../../../assets/images/home/Group 3940.png";
import Service17 from "../../../../assets/images/home/Group 3941.png";

const Services = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });

  return (
    <Box backgroundColor="#F9F9F9">
      <Box
        className={isXLScreen ? "container" : "container-responsive"}
        paddingY={{ base: "50px", xl: "100px" }}
      >
        <Flex justifyContent="center">
          <Box width={{ base: "fit-content", xl: "601px" }}>
            <Text
              color="#000"
              textAlign="center"
              fontSize={{ base: "30px", md: "40px", xl: " 64px" }}
              fontStyle="normal"
              fontWeight={{ base: "600", xl: "500" }}
              lineHeight={{ base: "40px", xl: "70px" }}
              paddingBottom="50px"
            >
              Key Benefits
            </Text>
          </Box>
        </Flex>
        {/* steps-div */}
        <HStack
          gap="0px"
          sx={{
            overflowX: "scroll",
            "::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
          alignItems="center"
          justifyContent="space-between"
        >
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service1} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="163px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Direct, Real-time Investments
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service2} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="179px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  AI-Powered Document Analysis
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service3} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="190px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Precise Compliance Management
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service4} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="199px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Frictionless Payment Processing
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service5} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="88px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  On-Chain
                  <br />
                  Identity
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service6} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="200px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Automated Waterfall Calculations
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service7} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                //  width="222px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Back-Office Operations Simplified
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service8} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="175px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Automated Equity Allocations
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service9} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="145px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Cross-Chain Interoperability
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service10} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="129px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Easy Investor Management
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service11} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="122px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Tradability
                  <br />
                  and Liquidity
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service12} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="95px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  One-Stop
                  <br />
                  Platform
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service13} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="147px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Efficient Credit
                  <br /> Servicing
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service14} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="147px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Swift and Easy Onboarding
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service15} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="233px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Unparalleled Investment Transparency
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" borderRight="1px solid #CCC">
            <Box width={{ base: "220px", xl: "240px" }} paddingY="30px">
              <Flex justifyContent="center">
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="#FFF3EB"
                >
                  <Image height="80px" src={Service16} />
                </Flex>
              </Flex>
              <Box
                paddingTop="30px"
                // width="127px"
              >
                <Text
                  textAlign="center"
                  color="#666"
                  fontSize=" 20px"
                  fontStyle=" normal"
                  fontWeight=" 400"
                  lineHeight="28px"
                >
                  Scalable
                  <br /> Infrastructure
                </Text>
              </Box>
            </Box>
          </Box>
          <Box paddingX="30px" paddingY="30px">
            <Flex
              width={{ base: "220px", xl: "240px" }}
              justifyContent="center"
            >
              <Flex
                justifyContent="center"
                alignItems="center"
                backgroundColor="#FFF3EB"
              >
                <Image height="80px" src={Service17} />
              </Flex>
            </Flex>
            <Box
              paddingTop="30px"
              // width="166px"
            >
              <Text
                textAlign="center"
                color="#666"
                fontSize=" 20px"
                fontStyle=" normal"
                fontWeight=" 400"
                lineHeight="28px"
              >
                Market Efficiency and Connectivity
              </Text>
            </Box>
          </Box>
        </HStack>
      </Box>
    </Box>
  );
};

export default Services;
