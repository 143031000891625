import React from "react";
import { Box, Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import TalkToUsUtils from "../../../../../../components/talkToUsSub";
const TalkToUsSub = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box paddingY={{ base: "60px", xl: "120px" }} backgroundColor="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          alignItems="start"
          justifyContent="space-between"
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Box width={{ base: "100%", xl: "55%" }}>
            <Text
              color="#666"
              fontSize="24px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="40px"
            >
              Stepping into the realm of enhanced investor management is a
              strategic move towards greater efficiency and engagement. At AKRU
              IM, we understand the nuances of investor relations and offer a
              platform that not only simplifies but elevates your investor
              management processes.
              <Text paddingY="30px">
                Go in-depth with our experts and explore the various ways in
                which AKRU IM can streamline your workflow and enhance
                decision-making processes
              </Text>
              Our discussion will be tailored to your specific needs, focusing
              on how AKRU IM can address your unique challenges and objectives
              in investor management.
            </Text>
          </Box>
          <Box
            marginTop={{ base: "40px", xl: "0px" }}
            backgroundColor="#FFF3EB"
            borderRadius="10px"
            paddingX="30px"
            paddingY="40px"
            width={{ base: "100%", xl: "40%" }}
          >
            <TalkToUsUtils formName="Akru IM Talk to Us" />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
export default TalkToUsSub;
