import React from "react";
import { Route, Routes } from "react-router-dom";
import ComingSoon from "../Views/ComingSoon";
import Home from "../Views/Home";
import AkruAdmin from "../Views/AkruAdmin";

import AkruInvestorManagment from "../Views/AkruInvestorManagment";
import AkruAlts from "../Views/AkruAlts";
import AboutUs from "../Views/AboutUs";
import ContactUs from "../Views/ContactUs";
import Blog from "../Views/Blog";
import AkruServicing from "../Views/AkruServicing";
// import Events from "../Views/Events";
import OnFiNetwork from "../Views/AOnFiNetwork";
import DocumentAnalyzer from "../Views/DocumentAnalyzer";
import BlogDetails from "../Views/BlogDetails";
import LearnMore from "../Views/AkruAdmin/components/LearnMore";
import DownloadPages from "../Views/AkruAdmin/components/downloadPages";
import TalkToUs from "../Views/AkruAdmin/components/talkToUs";
import HomeSubPage from "../Views/Home/components/homeSubPage";
import TalkToUsAlts from "../Views/AkruAlts/components/talkToUsAlts";
import DownloadAltsSub from "../Views/AkruAlts/components/downloadAltsSub";
import TalkToUsIm from "../Views/AkruInvestorManagment/components/talkToUsIm";
import DownloadPageIm from "../Views/AkruInvestorManagment/components/downloadPageIm";
import LearnMoreIm from "../Views/AkruInvestorManagment/components/learnMoreIm";
import LearnMoreServicing from "../Views/AkruServicing/components/learnMoreServicing";
import TalkToUsServicing from "../Views/AkruServicing/components/talkToUsServicing";
import DownloadPageServicing from "../Views/AkruServicing/components/downloadPageServicing";
import LearnMoreAonFi from "../Views/AOnFiNetwork/components/learnMoreAonFi";
import TalkToUsAonFi from "../Views/AOnFiNetwork/components/talkToUsAonFi";
import DownloadPageAonfi from "../Views/AOnFiNetwork/components/downloadPageAonFi";
import LearnMoreDataAnalyzer from "../Views/DocumentAnalyzer/components/learnMoreDataAnalyzer";
import TalkToUsDataAnalyzer from "../Views/DocumentAnalyzer/components/talkToUsDataAnalyzer";
import DownloadPageDocumentAnalyzer from "../Views/DocumentAnalyzer/components/downloadPageDocumentAnalyzer";
import LearnMoreAlts from "../Views/AkruAlts/components/learnMoreAlts";
import BlogDetails2 from "../Views/BlogDetails2";
import BlogDetails3 from "../Views/BlogDetails3";
import BlogDetails4 from "../Views/BlogDetails4";

const RoutesPage = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/akru-admin" element={<AkruAdmin />} />
      <Route
        path="/akru-investor-managment"
        element={<AkruInvestorManagment />}
      />
      <Route path="/akru-alts" element={<AkruAlts />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/akru-servicing" element={<AkruServicing />} />
      {/* <Route path="/events" element={<Events />} /> */}
      <Route path="/a-onfi-network" element={<OnFiNetwork />} />
      <Route path="/document-analyzer" element={<DocumentAnalyzer />} />
      <Route path="/blog-details" element={<BlogDetails />} />
      <Route path="/blog-details-sub2" element={<BlogDetails2 />} />
      <Route path="/blog-details-sub3" element={<BlogDetails3 />} />
      <Route path="/blog-details-sub4" element={<BlogDetails4 />} />
      <Route path="/learn-more" element={<LearnMore />} />
      <Route path="/download-pages" element={<DownloadPages />} />
      <Route path="/talk-to-us" element={<TalkToUs />} />
      <Route path="/learn-more-home" element={<HomeSubPage />} />
      <Route path="/learn-more-alts" element={<LearnMoreAlts />} />
      <Route path="/talk-to-us-alts" element={<TalkToUsAlts />} />
      <Route path="/download-alts-sub" element={<DownloadAltsSub />} />
      <Route path="/talk-to-us-im" element={<TalkToUsIm />} />
      <Route path="/download-page-im" element={<DownloadPageIm />} />
      <Route path="/learn-more-im" element={<LearnMoreIm />} />
      <Route path="/learn-more-servicing" element={<LearnMoreServicing />} />
      <Route path="/talk-to-us-servicing" element={<TalkToUsServicing />} />
      <Route
        path="/download-page-servicing"
        element={<DownloadPageServicing />}
      />
      <Route path="/learn-more-a-onfi" element={<LearnMoreAonFi />} />
      <Route path="/talk-to-us-a-onfi" element={<TalkToUsAonFi />} />
      <Route path="/download-page-a-onfi" element={<DownloadPageAonfi />} />
      <Route
        path="/learn-more-data-analyzer"
        element={<LearnMoreDataAnalyzer />}
      />
      <Route
        path="/talk-to-us-data-analyzer"
        element={<TalkToUsDataAnalyzer />}
      />
      <Route
        path="/download-page-data-analyzer"
        element={<DownloadPageDocumentAnalyzer />}
      />
      <Route path="/comingSoon" element={<ComingSoon />} />
    </Routes>
  );
};

export default RoutesPage;
