import React from "react";
import {
  Box,
  Flex,
  Text,
  //   Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import TalkToUsUtil from "../../../../../../components/talkToUsSub";

const TalkToUsSub = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box paddingY={{ base: "60px", xl: "120px" }} backgroundColor="#F9F9F9">
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        <Flex
          alignItems="start"
          justifyContent="space-between"
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Box width={{ base: "100%", xl: "60%" }}>
            <Text
              color="#666"
              fontSize="24px"
              fontStyle="normal"
              fontWeight="400"
              lineHeight="40px"
            >
              Explore the transformative potential of the A-On Fi Network in
              reshaping your financial operations. Our network is at the
              forefront of reshaping financial systems, focusing on enhancing
              operational agility, ensuring regulatory compliance, and
              fortifying security measures.
              <Text paddingY="30px">
                Connect with our experts to explore the unique advantages of
                A-On Fi Network, including its capacity to enable efficient
                cross-chain transactions and its role in fostering a more
                interconnected and responsive financial ecosystem.
              </Text>
              Learn how our network and its advanced blockchain based technology
              are paving the way for a more dynamic and secure financial future.
              Our team of experts are eager to show you how A-On Fi Network is
              not only breaking down financial silos but also creating a more
              fluid and interconnected financial landscape.
            </Text>
          </Box>
          <Box
            marginTop={{ base: "40px", xl: "0px" }}
            backgroundColor="#FFF3EB"
            borderRadius="10px"
            paddingX="30px"
            paddingY="40px"
            width={{ base: "100%", xl: "35%" }}
          >
            <TalkToUsUtil formName="A OnFi  Network Talk to Us" />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
export default TalkToUsSub;
