import { Box, Flex, Image, Text, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import Fade from "react-reveal/Fade";
import CommunicationImage2 from "../../../../assets/images/AkruInvestorManagment/Group 3882.png";
import LeadManagmentImage1 from "../../../../assets/images/AkruInvestorManagment/Group.png";
const Communication = () => {
  const isXLScreen = useBreakpointValue({ base: false, xl: true });
  return (
    <Box>
      <Box className={isXLScreen ? "container" : "container-responsive"}>
        {/* section-2 */}
        <Flex
          paddingY={{ base: "0px", xl: "120px" }}
          flexDirection={{ base: "column-reverse", xl: "row" }}
          paddingBottom={{ base: "60px", xl: "120px" }}
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
        >
          <Box
            position="relative"
            paddingTop={{ base: "50px", xl: "0px" }}
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={CommunicationImage2}
            />
          </Box>
          <Fade right>
            <Box paddingTop={{ base: "60px", xl: "0px" }}>
              <Text
                width={{ base: "fit-content", xl: "411px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Integrated Email Communication
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                Directly connect with your investors through the platform,
                enabling efficient email communication and engagement.
              </Text>
            </Box>
          </Fade>
        </Flex>
        {/* section-1 */}
        <Flex
          paddingBottom={{ base: "60px", xl: "100px" }}
          justifyContent="space-evenly"
          alignItems={{ base: "start", xl: "center" }}
          flexDirection={{ base: "column", xl: "row" }}
        >
          <Fade left>
            <Box>
              <Text
                width={{ base: "fit-content", xl: "303px" }}
                color="#000"
                fontSize={{ base: "30px", md: "40px", xl: "48px" }}
                fontStyle="normal"
                fontWeight={{ base: "600", xl: "500" }}
                lineHeight={{ base: "40px", xl: " 65px" }}
              >
                Holistic Lead Management
              </Text>
              <Text
                paddingTop="30px"
                width={{ base: "fit-content", xl: "554px" }}
                color="#666"
                fontSize={{ base: "16px", xl: "24px" }}
                fontStyle="normal"
                fontWeight="400"
                lineHeight={{ base: "30px", xl: " 40px" }}
              >
                From initial contact to conversion, manage leads with an
                intuitive and centralized approach.
              </Text>
            </Box>
          </Fade>
          <Box
            paddingTop={{ base: "50px", xl: "0px" }}
            position="relative"
            height={{ base: "inherit", xl: "fit-content" }}
            overflow="unset"
          >
            <Image
              height={{ base: "fit-content", xl: "400px" }}
              src={LeadManagmentImage1}
            />
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default Communication;
